@use "settings/s-colors" as colors;

.s-product-intro__grid {
  grid-template-columns: 1fr 624px;
  grid-template-areas: "gallery content";
  gap: 96px;

  @include media-breakpoint-down(xxl) {
    grid-template-columns: 1fr 1fr;
    gap: 48px;
  }

  @include media-breakpoint-down(lg) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "gallery"
      "content";
  }

  @include media-breakpoint-down(sm) {
    gap: 32px;
  }
}

.s-product-intro__gallery-slider {
  grid-area: gallery;
}

.s-product-intro__content {
  grid-area: content;
}

.s-product-intro__param-items {
  border-bottom: 1px solid colors.$secondary-400;
}

.s-product-intro__param-decor {
  height: 1px;
  pointer-events: none;
}

.s-product-intro__price {
  background: linear-gradient(90deg, rgb(255 255 255 / 10%) 0%, colors.$primary-400 100%);
}

.s-product-intro__buttons.g-base {
  @include media-breakpoint-down(xxl) {
    gap: 16px;
  }

  @include media-breakpoint-only(md) {
    gap: 0;
  }

  .btn {
    width: 50%;

    @include media-breakpoint-down(xxl) {
      width: 100%;
    }

    @include media-breakpoint-only(md) {
      width: 50%;
    }
  }
}
