@use "settings/s-colors" as colors;

.s-calendar__app {
  --fc-border-color: #{colors.$gray-200};
  --fc-today-bg-color: #{colors.$primary-100};
  --fc-event-bg-color: #{colors.$primary};
  --fc-button-bg-color: #{colors.$primary};
  --fc-button-disabled-bg-color: #{colors.$secondary-500};
  --fc-button-disabled-border-color: #{colors.$secondary-500};
  --fc-button-border-color: #{colors.$primary};
  --fc-button-active-bg-color: #{colors.$conversion-500};
  --fc-button-active-border-color: #{colors.$conversion-500};
  --fc-button-hover-bg-color: #{colors.$conversion-500};
  --fc-button-hover-border-color: #{colors.$conversion-500};

  height: 570px;

  &.fc {
    height: auto;

    .fc-button {
      border-radius: 0;
    }

    .fc-button-primary {
      &:disabled {
        background-color: var(--fc-button-disabled-bg-color);
        border-color: var(--fc-button-disabled-border-color);
        opacity: 1;
      }
    }
  }

  &.--close {
    display: none;
  }

  .fc-daygrid {
    background-color: colors.$white;
  }

  .fc-event {
    border-radius: 0;
    border: none;
  }

  .fc-daygrid-day-number {
    text-decoration: none;
    color: colors.$primary;
  }

  .fc-event-time {
    flex-shrink: 0;
  }

  .fc-col-header-cell-cushion {
    text-decoration: none;
  }

  .fc-col-header-cell {
    padding: 8px;
  }

  .fc-toolbar-chunk {
    display: flex;
    flex-wrap: wrap;
    row-gap: 4px;
    justify-content: flex-end;
  }
}

.s-calendar__toggler {
  &.--close {
    svg {
      transform: scale(-1);
    }
  }
}
