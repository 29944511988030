.i-gallery-slide {
  --border-color: transparent;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    pointer-events: none;
    z-index: 2;
    border: 1px solid var(--border-color);
  }

  &.--large {
    padding: 16px;

    &::before {
      display: none;
    }
  }
}

.i-gallery-slide__image {
  display: block;

  button {
    background: none;
    border: none;
    outline: none;
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;

    .img {
      aspect-ratio: 1 / 1.25;
    }
  }

  a {
    display: block;
    outline: none;
    text-decoration: none;
  }
}

.i-gallery-slide__tags {
  top: 16px;
  left: 16px;
  pointer-events: none;
}

.i-gallery-slide__grant {
  top: 16px;
  right: 16px;
  pointer-events: none;
  transform: translate(41px, -40px);
}
