@use "settings/s-colors.scss" as colors;

$styleTypes: "lower-alpha", "upper-alpha", "lower-latin", "upper-latin", "lower-greek", "upper-greek", "lower-roman", "upper-roman";
/* stylelint-disable no-descending-specificity */

.entry-content {
  ol {
    & > li {
      position: relative;
      counter-increment: item;
      padding-left: 24px;

      &::before {
        content: counter(item) ".";
        font-weight: 700;
        font-size: 18px;
        top: 1px;
        width: 28px;
        height: 28px;
        display: inline-flex;
        margin-right: 8px;
        color: colors.$primary;
        margin-left: -35px;
        padding: 1px 4px;
        padding-left: 6px;
      }

      ol > li {
        counter-increment: sub-item;

        &::before {
          content: counter(sub-item) ".";
        }

        ol > li {
          counter-increment: sub-sub-item;

          &::before {
            content: counter(sub-sub-item) ".";
          }
        }
      }
    }

    @each $type in $styleTypes {
      &[style*="list-style-type: #{$type}"] {
        list-style-type: none !important;

        li {
          &::before {
            content: counter(item, #{$type}) ")";
          }
        }
      }
    }
  }

  ul {
    list-style-type: none;

    & > li {
      padding-left: 24px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        display: block;
        top: 8px;
        left: 0;
        width: 8px;
        height: 8px;
        background-color: colors.$primary;
      }
    }
  }

  ul,
  ol {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 24px;

    ul,
    ol {
      margin-bottom: 0;
      margin-top: 8px;
    }

    li {
      margin-bottom: 8px;
    }
  }
}
/* stylelint-enable no-descending-specificity */
