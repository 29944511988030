@use "settings/s-colors.scss" as colors;

.i-usp__title {
  padding-inline: var(--padding-x);

  @include media-breakpoint-down(xxl) {
    --padding-x: 32px;
  }

  @include media-breakpoint-down(xl) {
    --padding-x: 16px;
  }

  @include media-breakpoint-down(sm) {
    --padding-x: 12px;

    font-size: 14px;
    line-height: 20px;
  }
}

.i-usp {
  --padding-x: 56px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: colors.$secondary-400;

    @include media-breakpoint-down(sm) {
      right: -4px;
      transform: translateX(50%);
    }
  }

  &.--product {
    --padding-x: 0;

    align-items: flex-start;
    text-align: left;

    .i-usp__title {
      text-align: left;
      padding-inline: 0;
    }
  }
}

.i-usp__icon {
  min-width: 96px;
  min-height: 56px;

  &.--number {
    gap: 4px;

    @include media-breakpoint-down(sm) {
      gap: 2px;

      .icon {
        --size: 18px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    min-width: 68px;
    min-height: 40px;

    .icon {
      --size: 40px;
    }
  }
}

.i-usp__number {
  font-size: 56px;

  @include media-breakpoint-down(sm) {
    font-size: 32px;
  }
}

.i-usp__count {
  font-size: 48px;
  line-height: 48px;
}

.i-usp__square {
  background-color: colors.$primary;
  width: 8px;
  height: 8px;
}
