@use "settings/s-colors" as colors;

.btn {
  &.--primary {
    --background: #{colors.$conversion-500};

    background-color: var(--background);
    transition: background-color 0.3s ease;
    will-change: background-color;

    @media (hover: hover) {
      &:hover {
        --background: #{colors.$text-500};
      }
    }
  }

  &.--secondary {
    --background: #{colors.$secondary-300};

    background-color: var(--background);
    transition:
      color 0.3s ease,
      background-color 0.3s ease;
    will-change: color, background-color;

    @media (hover: hover) {
      &:hover {
        --background: transparent;
      }
    }
  }

  &.--outline {
    --font-color: #{colors.$conversion-500};
    --border-color: #{colors.$secondary-400};
    --background: #{colors.$white};
    --polygon-size: 0;

    background-color: var(--background);
    transition: background-color 0.3s ease;
    will-change: background-color;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border: 1px solid var(--border-color);
      z-index: -2;
    }

    &::after {
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      bottom: 1px;
      right: 1px;
      background-color: var(--background);
      z-index: -1;
      clip-path: polygon(0% 0%, 100% 0, 100% calc(100% - var(--polygon-size)), calc(100% - var(--polygon-size)) 100%, 0% 100%);
      transition: background-color 0.3s ease;
    }

    @media (hover: hover) {
      &:hover {
        --background: #{rgba(colors.$secondary-400, 50%)};
      }
    }
  }

  &.--ghost {
    --background: transparent;
    --font-color: #{colors.$conversion-500};

    background-color: var(--background);
    transition: background-color 0.3s ease;
    will-change: background-color;

    @media (hover: hover) {
      &:hover {
        --background: #{colors.$primary-400};
      }
    }
  }
}
