@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.i-menu__submenu-button {
  --size: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: var(--size);
  rotate: var(--submenu-button-rotate);
  margin-left: auto;

  @include media-breakpoint-down(lg) {
    --size: 48px;

    transition: scale 0.3s ease;
    will-change: scale;
    scale: var(--submenu-button-scale);
    position: absolute;
    top: 0;
    right: 24px;

    @media (hover: hover) {
      &:hover {
        svg {
          transform: translateY(4px);
        }
      }
    }
  }

  svg {
    transition: transform 0.3s ease;
  }
}

.i-menu {
  --submenu-display: none;
  --submenu-button-scale: 1;
  --submenu-button-rotate: 0deg;
  --padding-left: 24px;
  --padding-right: 48px;
  --background: transparent;
  --background-hover: #{colors.$secondary-300};
  --color: #{colors.$text-500};

  &.--open {
    --submenu-display: block;
    --submenu-button-scale: -1;
  }

  &.--active {
    --color: #{colors.$conversion-500};
  }

  & > .i-menu__submenu {
    display: var(--submenu-display);
  }
}

.i-menu__link {
  display: flex;
  gap: 8px;
  padding-block: 8px;
  min-height: 40px;
  align-items: center;
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
  position: relative;
  text-decoration: none;
  background-color: var(--background);
  color: var(--color);
  transition: background-color 0.3s ease;
  transition-property: background-color, color;
  will-change: background-color, color;

  @media (hover: hover) {
    &:hover {
      --background: var(--background-hover);
    }
  }
}

.i-menu.--level-2 {
  --padding-left: 24px;
}

.i-menu.--level-3 {
  --padding-left: 32px;
}

@include media-breakpoint-down(lg) {
  .i-menu {
    --active-line: transparent;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 3px;
      background-color: var(--active-line);
      z-index: 1;
      transition: background-color 0.3s ease;
    }

    &.--active {
      --color: #{colors.$text-500};
      --active-line: #{colors.$conversion-500};
    }
  }

  .i-menu.--level-2 {
    --background: var(--background-hover);

    &.--active {
      --background: #{colors.$white};
    }
  }

  .i-menu__link {
    min-height: 48px;
  }
}

// Desktop

@include media-breakpoint-up(lg) {
  .i-menu {
    --padding-left: 16px;
    --padding-right: 16px;
    --submenu-display: block;
    --opacity: 0;
    --translate-y: 8px;
    --pointer-events: none;

    .i-menu__submenu {
      z-index: 50;
      width: 100%;
      min-width: 240px;
      position: absolute;
      background-color: colors.$white;
      box-shadow: shadows.$shadow;
      opacity: var(--opacity);
      pointer-events: var(--pointer-events);
      transform: translateY(var(--translate-y));
      transition: opacity 0.3s ease;
      transition-property: opacity, transform;
      will-change: opacity, transform;
    }

    &.--level-1 {
      position: relative;

      & > .i-menu__submenu {
        top: 100%;
        left: 0;
      }

      &:hover {
        --pointer-events: auto;
        --translate-y: 0;
        --opacity: 1;
      }
    }

    &.--level-2 {
      --submenu-button-rotate: -90deg;

      position: relative;

      & > .i-menu__submenu {
        top: 0;
        left: 100%;

        &.--mirror {
          left: auto;
          right: 100%;
        }
      }

      &:hover {
        --pointer-events: auto;
        --translate-y: 0;
        --opacity: 1;
      }
    }
  }
}

@include media-breakpoint-between(lg, xxl) {
  .i-menu {
    --padding-left: 8px;
    --padding-right: 8px;
  }
}
