@use "settings/s-colors" as colors;

.i-search-result {
  max-width: 80ch;
}

.i-search-result__heading-link {
  color: colors.$conversion-500;
  text-decoration-color: transparent;

  &:hover {
    color: colors.$conversion-500;
    text-decoration-color: colors.$conversion-500;
  }
}

.i-search-result__link {
  text-decoration-color: transparent;
  transition-property: color, text-decoration-color;

  &:hover {
    color: colors.$primary;
    text-decoration-color: colors.$primary;
  }
}
