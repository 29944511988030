@use "settings/s-fonts.scss" as fonts;
@use "settings/s-colors.scss" as colors;

body {
  font-family: fonts.$primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale !important;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  line-height: 1.5;
  color: colors.$text-500;
}
