html {
  height: 100%;
  text-size-adjust: none;
  scroll-behavior: smooth;
  scroll-padding-top: 112px;

  @include media-breakpoint-down(xl) {
    scroll-padding-top: 88px;
  }
}
