.g-product {
  @include media-breakpoint-down(md) {
    max-width: 360px;
    margin-inline: auto;
  }

  @media (width <= 392px) {
    max-width: 100%;
  }
}

.g-product__cell {
  @media (width <= 392px) {
    margin-inline: 0;
    padding-inline: 0;
  }
}
