@use "settings/s-colors" as colors;

.i-banner {
  background: radial-gradient(49.01% 570.7% at 0% 100%, #13282b 0%, #{colors.$primary} 100%);
  border: 8px solid colors.$primary;
  padding: 48px 64px 48px 296px;

  @include media-breakpoint-up(lg) {
    margin-block: 64px;
  }

  @include media-breakpoint-down(xxl) {
    padding-block: 24px;
  }

  @include media-breakpoint-down(xl) {
    padding: 24px 40px 24px 264px;
  }

  @include media-breakpoint-down(lg) {
    padding: 16px;
  }
}

.i-banner__header {
  max-width: 712px;

  @include media-breakpoint-down(xxl) {
    max-width: 424px;
  }

  @include media-breakpoint-down(xl) {
    max-width: 100%;
  }
}

.i-banner__img {
  left: 32px;
  transform: translateY(4px);
  max-width: 200px;
  height: auto;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.i-banner__decoration {
  transform: translate(-72px, 72px);

  @include media-breakpoint-down(lg) {
    max-width: 96px;
    max-height: 96px;
    transform: translate(-40px, 40px);
  }
}

.i-banner__buttons {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
