.u-h1 {
  --size: 64px;
  --line-height: calc(var(--size) + 8px);

  @include media-breakpoint-down(md) {
    --size: 40px;
  }
}

.u-h2 {
  --size: 40px;
  --line-height: calc(var(--size) + 8px);

  @include media-breakpoint-down(md) {
    --size: 32px;
  }
}

.u-h3 {
  --size: 32px;
  --line-height: var(--size);

  @include media-breakpoint-down(md) {
    --size: 24px;
  }
}

.u-h4 {
  --size: 24px;
  --line-height: var(--size);

  @include media-breakpoint-down(md) {
    --size: 18px;
    --line-height: calc(var(--size) + 2px);
  }
}

.u-h5 {
  --size: 16px;
  --line-height: calc(var(--size) + 4px);

  @include media-breakpoint-down(md) {
    --size: 16px;
  }
}

.u-h1,
.u-h2,
.u-h3,
.u-h4,
.u-h5 {
  font-size: var(--size);
  line-height: var(--line-height);
  font-weight: 700;
}
