@use "settings/s-colors" as colors;

.i-notification {
  --min-height: 48px;

  min-height: var(--min-height);

  @include media-breakpoint-down(md) {
    text-align: center;
  }

  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}

.i-notification__container {
  width: min(100% - 32px, 1408px);

  @include media-breakpoint-down(md) {
    flex-direction: column;
    gap: 8px;
  }
}

.i-notification__body {
  @include media-breakpoint-down(md) {
    align-items: flex-start;
    gap: 0;
    padding-inline: 24px;
  }
}

.i-notification__close {
  @include media-breakpoint-down(md) {
    position: absolute;
    right: 8px;
  }
}
