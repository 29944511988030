@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.c-language-select {
  --dropdown-opacity: 0;
  --dropdown-pointer-events: none;

  &:hover {
    --dropdown-opacity: 1;
    --dropdown-pointer-events: auto;
  }
}

.c-language-select__drowpdown {
  background-color: colors.$white;
  transition: opacity 0.3s ease;
  top: 100%;
  left: 0;
  opacity: var(--dropdown-opacity);
  pointer-events: var(--dropdown-pointer-events);
  box-shadow: shadows.$shadow;
}

.c-language-select__active {
  min-height: 40px;
}

.c-language-select__dropdown-link {
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: colors.$secondary-400;
  }
}
