@use "settings/s-colors" as colors;

.g-product-slider__button {
  --offset: calc(50% - 4px);

  pointer-events: auto;

  &.btn.--secondary {
    --background: #{colors.$secondary-400};
    --min-height: 64px;
    --polygon-size: 16px;
    --svg-size: 16px;

    border: 3px solid colors.$white;

    @media (hover: hover) {
      &:hover {
        --background: #{colors.$secondary-400};
      }
    }

    @media (width <= 359px) {
      --min-height: 32px;
      --offset: calc(50% - 12px);
    }
  }

  &.--prev {
    transform: translateX(calc(-1 * var(--offset)));
    clip-path: polygon(0 0, 100% 0, 100% 100%, 16px 100%, 0 calc(100% - var(--polygon-size)));
  }

  &.--next {
    transform: translateX(var(--offset));
  }

  & .icon.--lg {
    --size: 20px;
  }
}

.g-product-slider {
  &.--loading {
    &::before {
      border-radius: 32px;
    }
  }

  &.swiper {
    display: flex;
    align-items: center;
    overflow: initial;
  }

  & .swiper-wrapper {
    display: flex;
    align-items: center;
  }

  .swiper-slide {
    flex-shrink: 0;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }

  .swiper-slide-visible {
    opacity: 1;
    pointer-events: auto;
  }
}

.g-product-slider__buttons {
  pointer-events: none;

  .swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }
}
