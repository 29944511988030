.s-product-list__subheader {
  margin-top: 64px;

  @include media-breakpoint-down(sm) {
    max-width: 328px;
    margin-inline: auto;
  }

  @media (width <= 392px) {
    max-width: 100%;
  }
}
