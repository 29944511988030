.g-buttons {
  --spacing: 64px;

  margin-top: var(--spacing);

  @include media-breakpoint-down(sm) {
    --spacing: 24px;
  }

  &.--spacing-sm {
    --spacing: 40px;
  }

  &.--spacing-md {
    --spacing: 48px;

    @include media-breakpoint-down(sm) {
      --spacing: 24px;
    }
  }

  &.--spacing-none {
    --spacing: 0;
  }
}
