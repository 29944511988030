@use "settings/s-colors" as colors;

.f-single-select {
  @include media-breakpoint-down(xxl) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.f-single-select__select.slim-select {
  &.ss-main {
    background-color: colors.$secondary-400;
    padding-inline: var(--padding-inline);
    transition: border 0.3s ease;

    &::after {
      display: block;
      content: "";
      width: 16px;
      height: 16px;
      background-image: url("../images/svg/chevron-down-select.svg");
      background-repeat: no-repeat;
      justify-self: end;
      align-self: center;
      grid-area: select;
      pointer-events: none;
      scale: 1;
      transition: scale 0.3s ease;
    }
  }

  &.ss-values {
    &.ss-single {
      margin: 0;
    }
  }

  &.ss-content {
    position: absolute;
  }

  .ss-list {
    padding: 8px;

    .ss-option {
      width: calc(100% - 8px);
      padding-inline: 16px;
      padding-block: 8px;
      transition: background-color 0.3s ease;

      &:hover {
        color: colors.$text-500;
        background-color: colors.$tertiary-300;
      }

      @include media-breakpoint-down(sm) {
        padding-inline: 8px;
      }
    }
  }

  .ss-arrow {
    display: none;
  }
}

.f-single-select,
.f-single-select__select.slim-select {
  --radius: 0;
  --height: 56px;
  --padding-inline: 24px;
  --padding-block: 10px;
  --border-color: transparent;
  --ss-primary-color: #{colors.$text-500};
  --ss-bg-color: #fff;
  --ss-font-color: #{colors.$text-500};
  --ss-font-placeholder-color: #{colors.$text-500};
  --ss-disabled-color: #dcdee2;
  --ss-border-color: var(--border-color);
  --ss-error-color: colors.$danger;
  --ss-main-height: var(--height);
  --ss-content-height: 256px;
  --ss-spacing-l: 7px;
  --ss-spacing-m: 12px;
  --ss-spacing-s: 0;
  --ss-animation-timing: 0.2s;
  --ss-border-radius: var(--radius);
  --ss-focus-color: transparent;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  &:hover {
    --border-color: #{colors.$text-500};
  }

  &.--native {
    .f-single-select__wrap {
      display: grid;
      align-items: center;
      grid-template-areas: "select";

      &::after {
        display: block;
        content: "";
        width: 16px;
        height: 16px;
        background-image: url("../images/svg/chevron-down-select.svg");
        background-repeat: no-repeat;
        justify-self: end;
        grid-area: select;
        margin-right: 24px;
        pointer-events: none;

        @include media-breakpoint-down(sm) {
          margin-right: 16px;
        }
      }
    }

    .f-single-select__select {
      color: colors.$text-500;
      font-weight: 500;
      border-radius: var(--radius);
      width: 100%;
      min-height: var(--height);
      border: 1px solid var(--border-color);
      background-color: colors.$secondary-400;
      padding-block: var(--padding-block);
      padding-inline: var(--padding-inline);
      padding-right: 40px;
      cursor: pointer;
      outline: none;
      margin: 0;
      appearance: none;
      grid-area: select;
      transition: border 0.3s ease;
    }
  }

  &.ss-open-below {
    --border-color: #{colors.$text-500};

    &::after {
      scale: -1;
    }
  }

  &.--invalid {
    --border-color: #{colors.$danger};

    .ss-main {
      --border-color: #{colors.$danger};
    }
  }

  @include media-breakpoint-down(xxl) {
    --height: 48px;
    --padding-inline: 16px;
  }
}

.f-single-select__label {
  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}
