@use "settings/s-colors.scss" as colors;

.f-label {
  --top: 50%;
  --translate-y: -50%;
  --color: #{colors.$text-500};

  position: absolute;
  top: var(--top);
  left: 16px;
  margin-right: 1px;
  transform: translateY(var(--translate-y));
  transition: 0.3s ease;
  transition-property: transform, color, font-size, top, left;
  color: var(--color);
  background-color: colors.$white;
  pointer-events: none;
  padding-inline: 8px;
  z-index: 2;

  &.--required {
    &::after {
      content: "*";
      color: var(--color);
      padding-left: 2px;
    }
  }

  &.--active {
    --top: 0;

    font-size: 12px;
    line-height: 20px;
  }
}
