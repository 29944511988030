@use "settings/s-colors.scss" as colors;

.link {
  &.--black {
    color: colors.$black;
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      color: colors.$black;
      text-decoration-color: colors.$black;
    }
  }

  &.--white {
    color: colors.$white;
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      color: colors.$white;
      text-decoration-color: colors.$white;
    }
  }

  &.--primary {
    color: colors.$text-500;
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      .icon.--primary {
        --color: #{colors.$conversion-500};
      }

      color: colors.$conversion-500;
      text-decoration-color: colors.$conversion-500;
    }
  }

  &.--conversion {
    color: colors.$conversion-500;
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      color: colors.$conversion-500;
      text-decoration-color: colors.$conversion-500;
    }
  }
}
