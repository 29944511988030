@use "settings/s-colors.scss" as colors;

.u-color-primary {
  color: colors.$text-500;
}

.u-color-primary-muted {
  color: colors.$text-500;
  opacity: 0.5;
}

.u-color-primary-2 {
  color: colors.$primary;
}

.u-color-primary-400 {
  color: colors.$primary-400;
}

.u-color-primary-500 {
  color: colors.$primary-500;
}

.u-color-secondary {
  color: colors.$text-400;
}

.u-color-white {
  color: colors.$white;
}
