.main-footer-column__content.entry-content {
  p:last-child {
    margin-bottom: 0;
  }
}

.main-footer-column__logo {
  max-width: 142px;
  max-height: 120px;
}

.main-footer__mail {
  word-break: break-all;
}

.main-footer__phone,
.main-footer__mail {
  .link__title {
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
  }
}

.main-footer__location-link .link {
  align-items: flex-start;

  .link__icon {
    padding-block: 4px;
  }
}
