@use "settings/s-colors" as colors;

.i-document {
  --background: transparent;

  min-height: 72px;
  border: 1px solid colors.$secondary-400;
  background-color: var(--background);
  transition: background-color 0.3s ease;

  &:hover {
    --background: #{colors.$secondary-300};
  }
}

.i-document__title {
  text-overflow: ellipsis;
}

.i-document__button {
  .btn {
    --svg-size: 16px;
  }
}
