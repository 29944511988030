@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.m-nav {
  --transition-timing: 0;

  @include media-breakpoint-up(lg) {
    --margin-right: 32px;

    margin-left: auto;
    margin-right: var(--margin-right);

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: calc(var(--margin-right) / -2);
      transform: translate(-50%, -50%);
      height: 24px;
      width: 1px;
      background-color: colors.$secondary-500;

      @include media-breakpoint-only(lg) {
        right: -16px;
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    --margin-right: 64px;
  }

  @include media-breakpoint-down(lg) {
    position: fixed;
    right: 0;
    top: 0;
    background-color: colors.$white;
    box-shadow: shadows.$shadow;
    width: 100%;
    max-width: 420px;
    transform: translateX(100%);
    transition: transform var(--transition-timing) ease;
    z-index: 100;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    &.--open {
      transform: translateX(0);
    }

    &.--transition {
      --transition-timing: 0.3s;
    }
  }

  @include media-breakpoint-only(lg) {
    margin-right: 32px;
  }
}

.m-nav__list {
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: center;
  }

  @include media-breakpoint-between(lg, xxl) {
    gap: 8px;
  }
}

.m-nav__strip {
  background: colors.$secondary-500;
  height: 8px;
  width: 100%;
  max-width: 100%;
}

.m-nav__header::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 100%;
  width: 100%;
  height: 1px;
  pointer-events: none;
  background-color: colors.$secondary-500;
}

.m-nav__language-link {
  &:hover,
  &:focus {
    color: colors.$text-400;
  }
}
