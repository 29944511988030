.i-price-list {
  grid-template-columns: 1fr 1fr 152px 1fr;
  grid-template-areas: "wattage price grant code";
  column-gap: 4px;

  &:nth-child(even) {
    background: transparent;
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr auto;
    grid-template-areas:
      "wattage grant"
      "price grant"
      "code grant";
  }
}

.i-price-list__wattage {
  grid-area: wattage;
}

.i-price-list__price {
  grid-area: price;
}

.i-price-list__code {
  grid-area: code;
  padding-left: 40px;

  @include media-breakpoint-down(md) {
    padding-left: 0;
  }
}

.i-price-list__grant {
  min-height: 32px;
  padding-block: 6px;
  line-height: 20px;
}

.i-price-list__grant-wrapper {
  grid-area: grant;
}
