@use "settings/s-colors" as colors;
@use "settings/s-general" as general;

.s-error-404__heading {
  --font-size: #{general.$spacing * 22};

  font-size: var(--font-size);

  @include media-breakpoint-down(md) {
    --font-size: #{general.$spacing * 17};
  }
}

.s-error-404__sub-heading {
  margin-inline: auto;
  margin-bottom: general.$spacing * 3;
  max-width: 720px;
}

.s-error-404__perex {
  max-width: 720px;
  text-align: center;
  margin-inline: auto;
  margin-bottom: 0;
}
