.content-w-aside__content {
  @include media-breakpoint-up(xl) {
    grid-column: 1 / span 8;
  }
}

.content-w-aside__aside {
  row-gap: 64px;

  @include media-breakpoint-up(xl) {
    grid-column: span 4;
  }

  @include media-breakpoint-down(sm) {
    row-gap: 32px;
  }
}

.content-w-aside {
  --padding-y: 64px;

  padding-block: var(--padding-y);

  @include media-breakpoint-down(sm) {
    --padding-y: 32px;
  }

  &.--top-0 {
    padding-top: 0;
  }

  &.--none {
    --padding-y: 0;
  }

  &.--reverse {
    .content-w-aside__aside {
      order: -1;
      grid-column: span 3;
    }

    .content-w-aside__content {
      grid-column: 4 / span 9;
    }
  }
}

.content-w-aside__row {
  --gap: 32px;

  gap: var(--gap);

  @include media-breakpoint-down(xl) {
    --gap: 64px;
  }

  @include media-breakpoint-down(sm) {
    --gap: 32px;
  }

  @include media-breakpoint-up(xl) {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(12, 1fr);
  }

  @include media-breakpoint-down(xl) {
    display: flex;
    flex-direction: column;
  }
}
