@use "settings/s-colors" as colors;

.i-signpost__front-img {
  width: var(--front-width);
  aspect-ratio: var(--front-ratio);
}

.i-signpost__back-img {
  width: var(--back-width);
  height: var(--back-height);
  aspect-ratio: var(--back-ratio);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--gradient-primary);
    z-index: 1;
  }

  @include media-breakpoint-down(lg) {
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);
      width: 56px;
      height: 56px;
      background-image: url("../images/svg/union.svg");
      background-size: 56px;
      background-repeat: no-repeat;
      z-index: 2;
    }
  }

  @include media-breakpoint-down(sm) {
    &::after {
      right: 16px;
      width: 32px;
      height: 32px;
      background-size: 32px;
    }
  }
}

.i-signpost__heading {
  --font-size: 32px;

  font-weight: 700;

  @include media-breakpoint-down(lg) {
    font-size: var(--font-size);
    line-height: var(--font-size);
  }

  @include media-breakpoint-down(md) {
    --font-size: 24px;
  }

  @include media-breakpoint-down(sm) {
    --font-size: 18px;

    line-height: 24px;
  }
}

.i-signpost {
  --gradient-primary: linear-gradient(90deg, #{rgba(colors.$primary-400, 0)} 0%, #{rgba(colors.$primary-400, 1)} 100%);
  --gradient-secondary: linear-gradient(256.08deg, #{colors.$secondary-400} 0%, #{colors.$white} 59.93%);
  --min-height: 195px;
  --padding-left: calc(137px + 24px);
  --padding-right: 56px;
  --arrow-position: 24px;
  --opacity: 0;
  --front-width: 137px;
  --back-width: 100%;
  --back-height: 83px;
  --front-ratio: 1 / 1.423;
  --back-ratio: 1 / 0.121;

  min-height: var(--min-height);

  @include media-breakpoint-down(sm) {
    --arrow-position: 16px;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border: 1px solid colors.$secondary-400;
    z-index: 3;
    pointer-events: none;
  }

  @media (hover: hover) {
    &:hover {
      --opacity: 1;
      --arrow-position: 16px;
    }
  }

  &.--default {
    @include media-breakpoint-only(lg) {
      --padding-left: calc(90px + 24px);
      --front-width: 90px;
      --front-ratio: 1 / 2.17;
    }

    @include media-breakpoint-down(sm) {
      --min-height: 113px;
      --front-width: 80px;
      --front-ratio: 1 / 1.413;
      --back-height: 48px;
      --padding-left: calc(80px + 16px);
    }
  }

  &.--big {
    --min-height: 414px;
    --padding-left: calc(295px + 24px);
    --front-width: 295px;
    --front-ratio: 1 / 1.403;
    --back-ratio: 1 / 0.244;
    --back-height: 168px;

    grid-row: span 2;

    @include media-breakpoint-only(xl) {
      --padding-left: calc(200px + 24px);
      --front-width: 200px;
      --front-ratio: 1 / 2.07;
    }

    @include media-breakpoint-down(xl) {
      grid-column: span 2;

      &:nth-child(2) {
        order: -1;
      }
    }

    @include media-breakpoint-down(lg) {
      --min-height: 195px;
      --back-height: 83px;
      --padding-left: calc(137px + 24px);
      --front-width: 137px;
      --front-ratio: 1 / 1.423;
    }

    @include media-breakpoint-down(sm) {
      --min-height: 113px;
      --front-width: 80px;
      --front-ratio: 1 / 1.413;
      --back-height: 48px;
      --padding-left: calc(80px + 16px);
    }
  }
}

.i-signpost__link {
  inset: 0;
}

.i-signpost__body {
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--gradient-secondary);
    opacity: var(--opacity);
    z-index: -1;
    transition: opacity 0.3s ease;
  }

  @include media-breakpoint-down(sm) {
    padding-block: 14px;
  }
}

.i-signpost__arrow {
  right: var(--arrow-position);
  bottom: var(--arrow-position);
  transition: 0.3s ease;
  transition-property: right, bottom;
}

.i-signpost__overline {
  width: 40px;
  height: 3px;
}

.i-signpost__decoration {
  right: 24px;
}
