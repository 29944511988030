.u-fz-xs {
  --size: 12px;

  font-size: var(--size);
  line-height: calc(var(--size) + 8px);
}

.u-fz-sm {
  --size: 14px;

  font-size: var(--size);
  line-height: calc(var(--size) + 2px);
}

.u-fz-md {
  --size: 16px;

  font-size: var(--size);
  line-height: calc(var(--size) + 8px);
}

.u-fz-lg {
  --size: 18px;

  font-size: var(--size);
  line-height: calc(var(--size) + 2px);
}

.u-fz-xl {
  --size: 20px;

  font-size: var(--size);
  line-height: calc(var(--size) + 8px);
}

.u-fz-xxl {
  --size: 24px;

  font-size: var(--size);
  line-height: var(--size);

  @include media-breakpoint-down(sm) {
    --size: 16px;

    font-size: var(--size);
    line-height: calc(var(--size) + 4px);
  }
}

.u-fz-xxxl {
  --size: 24px;

  font-size: var(--size);
  line-height: calc(var(--size) + 8px);

  @include media-breakpoint-down(sm) {
    line-height: var(--size);
  }
}
