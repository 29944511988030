.btn {
  &.--lg {
    --font-size: 16px;
    --padding-y: 12px;
    --padding-x: 24px;
    --min-height: 56px;
  }

  &.--sm {
    --font-size: 14px;
    --padding-y: 12px;
    --padding-x: 24px;
    --min-height: 48px;
    --svg-size: 20px;
  }

  &.--xs {
    --font-size: 12px;
    --padding-y: 12px;
    --padding-x: 24px;
    --min-height: 40px;
    --svg-size: 24px;
  }

  &.--xxs {
    --font-size: 12px;
    --padding-y: 12px;
    --padding-x: 12px;
    --min-height: 32px;
    --svg-size: 16px;
  }
}
