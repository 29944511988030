@use "settings/s-colors" as colors;

.g-gallery-slider__wrapper {
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 8px;
    height: auto;
    width: 88px;
    max-height: 100%;
  }

  @include media-breakpoint-down(md) {
    order: 2;
  }

  .swiper-slide {
    opacity: 1;
    width: 88px;
  }

  .swiper {
    position: static;
    width: 100%;
    max-height: 100%;
  }
}

.g-gallery-slider__large-img {
  max-width: 568px;

  @include media-breakpoint-up(md) {
    margin-left: 104px;
  }
}

.g-gallery-slider__body {
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    pointer-events: none;
    z-index: -1;
    border: 1px solid colors.$secondary-400;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: calc(88px + 16px);
    transform: translate(-50%, -50%);
    pointer-events: none;
    width: 1px;
    height: calc(100% - 16px);
    background-color: colors.$secondary-400;
  }

  @include media-breakpoint-down(md) {
    &::after {
      display: none;
    }
  }
}

.swiper-slide-thumb-active {
  .i-gallery-slide {
    --border-color: #{colors.$conversion-500};
  }
}

div.fancybox__nav {
  button.carousel__button {
    --font-color: #{colors.$conversion-500};
    --border-color: #{colors.$secondary-400};

    background-color: colors.$white;
    border: 1px solid var(--border-color);
    border-radius: 0;
    overflow: hidden;
    transition: 0.6s ease;

    @media (hover: hover) {
      &:hover {
        --font-color: #{colors.$white};
      }
    }

    svg {
      display: none;
    }

    &::before {
      content: "";
      width: 24px;
      height: 24px;
      position: absolute;
      background-image: url("../images/svg/chevron-right-fancybox.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px;
      user-select: none;
      z-index: 1;
      transition:
        background-image 0.3s ease,
        transform 0.3s ease;
    }

    &.is-next {
      @media (hover: hover) {
        &:hover {
          &::before {
            transform: translateX(5px);
          }
        }
      }
    }

    &.is-prev {
      &::before {
        transform: rotate(-180deg);
      }

      @media (hover: hover) {
        &:hover {
          &::before {
            transform: translateX(-5px) rotate(-180deg);
          }
        }
      }
    }
  }
}

div.fancybox__backdrop {
  background-color: #002c5b66;
}

div.fancybox__thumb {
  border-radius: 0;

  &::after {
    border-width: 3px !important;
    border-color: colors.$conversion-500 !important;
    border-radius: 0 !important;
  }
}
