.g-usps {
  .row {
    row-gap: 72px;
    padding-block: 56px;
    margin: 0 -5px;

    @include media-breakpoint-down(md) {
      padding-block: 32px;
    }

    @include media-breakpoint-down(sm) {
      row-gap: 24px;
    }
  }

  &.--product {
    .row {
      margin: 0 -13px;
    }

    .g-usps__cell {
      padding-inline: 12px;

      @include media-breakpoint-down(sm) {
        &:nth-child(even) .i-usp {
          padding-left: 16px;
        }

        &:nth-child(odd) .i-usp {
          padding-right: 16px;
        }
      }
    }
  }
}
