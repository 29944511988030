.main-footer {
  --padding-top: 56px;

  padding-top: var(--padding-top);

  @include media-breakpoint-down(sm) {
    --padding-top: 24px;
  }
}

.main-footer__top {
  --padding-y: 64px;

  padding-block: var(--padding-y);

  @include media-breakpoint-down(sm) {
    --padding-y: 32px;
  }
}

.main-footer__columns {
  @include media-breakpoint-up(sm) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(3, 1fr) 240px;
    gap: 62px;
  }
}

.main-footer__strip {
  --height: 8px;
  --max-width: 100%;

  width: 100%;
  max-width: var(--max-width);
  height: var(--height);

  &.--upper {
    --height: 1px;
    --max-width: calc(100% - 52px);

    transform: translate(-50%, -32px);

    @include media-breakpoint-up(sm) {
      transform: translate(-50%, -64px);
    }
  }
}
