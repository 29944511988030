@use "settings/s-colors" as colors;

.i-product {
  --opacity: 0;
  --gradient-secondary: linear-gradient(256.08deg, #{colors.$secondary-400} 0%, #{colors.$white} 59.93%);
  --background: #{colors.$white};
  --arrow-position: 0;

  border: 1px solid colors.$secondary-400;
  background-color: var(--background);
  transition: background-color 0.3s ease;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--gradient-secondary);
    opacity: var(--opacity);
    z-index: -1;
    transition: opacity 0.3s ease;
  }

  @media (hover: hover) {
    &:hover {
      --opacity: 1;
      --background: transparent;
      --arrow-position: 8px;
    }
  }
}

.i-product__tags {
  top: 16px;
  left: 16px;
  pointer-events: none;
}

.i-product__grant {
  top: 16px;
  right: 16px;
  pointer-events: none;
  transform: translate(40px, -40px);
}

.i-product__arrow {
  transform: translate(var(--arrow-position), var(--arrow-position));
  transition: 0.3s ease;
  transition-property: transform;
}

.i-product__link {
  inset: 0;
}

.i-product__price-block {
  mix-blend-mode: multiply;
}
