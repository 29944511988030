@use "settings/s-colors" as colors;

.s-contact-overview__grid {
  @include media-breakpoint-up(xl) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
}

.s-contact-overview__operator {
  border: 1px solid colors.$secondary-400;
  grid-column: 1 / span 8;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.s-contact-overview__manufacturer {
  grid-column: span 4;
}

.s-contact-overview__img {
  width: 348px;

  .img {
    aspect-ratio: 1 / 1.023;
  }

  @include media-breakpoint-down(md) {
    max-width: 348px;
    width: 100%;
    margin-inline: auto;
  }
}

.s-contact-overview__operator-location .link,
.s-contact-overview__manufacturer-location .link {
  align-items: flex-start;

  .link__icon {
    margin-top: 4px;
  }
}

.s-contact-overview__mail {
  word-break: break-all;
}

.s-contact-overview__phone,
.s-contact-overview__mail {
  .link__title {
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
  }
}

.s-contact-overview__manufacturer-decoration {
  transform: translate(32px, 0);
  pointer-events: none;

  img {
    height: 256px;
  }
}
