.s-product-slider {
  overflow: hidden;
}

.s-product-slider__slider {
  @include media-breakpoint-down(md) {
    width: min(100%, 328px);
    margin-inline: auto;
  }
}
