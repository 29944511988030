@use "settings/s-colors.scss" as colors;

.s-hero {
  --gradient: linear-gradient(256.08deg, #{colors.$secondary-400} 0%, #{colors.$white} 59.93%);

  @include media-breakpoint-up(lg) {
    background: var(--gradient);
  }

  @include media-breakpoint-down(lg) {
    padding-top: 16px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: 328px;
      background: var(--gradient);
      z-index: -1;
    }
  }
}
