@use "settings/s-colors.scss" as colors;

.i-grant-widget {
  --arrow-position: 0;

  &::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 100%;
    height: 1px;
    background-color: colors.$secondary-400;
  }

  &:last-child::after {
    display: none;
  }

  @media (hover: hover) {
    &:hover {
      --arrow-position: -8px;
    }
  }
}

.i-grant-widget__arrow {
  right: var(--arrow-position);
  bottom: var(--arrow-position);
  transition: 0.3s ease;
  transition-property: right, bottom;
}

.i-grant-widget__grant {
  min-height: 36px;
  gap: 4px;
}

.i-grant-widget__link {
  inset: 0;
}
