@use "settings/s-colors" as colors;

.i-contact-partner__header {
  min-height: 56px;
}

.i-contact-partner__schedule-decor {
  height: 1px;
  pointer-events: none;
}

.i-contact-partner__image .img {
  max-width: 96px;
  max-height: 48px;
}

.i-contact-partner__location .link {
  align-items: flex-start;

  .link__icon {
    margin-top: 4px;
  }
}

.i-contact-partner__hour-item {
  min-height: 40px;
  row-gap: 4px;

  &:nth-child(odd) {
    background-color: colors.$secondary-300;
  }
}

.i-contact-partner__hour-label {
  max-width: 80px;
  width: 100%;
}

.i-contact-partner__mail,
.i-contact-partner__web {
  word-break: break-all;
}

.i-contact-partner__phone,
.i-contact-partner__mail,
.i-contact-partner__web {
  .link__title {
    line-height: 20px;
    font-weight: 600;
  }
}

.i-contact-partner {
  border: 1px solid colors.$secondary-400;

  &.--map {
    border: none;

    .i-contact-partner__hour-item {
      @include media-breakpoint-down(md) {
        padding-inline: 8px;
      }
    }
  }
}

.i-contact-partner__hours {
  &:not(:has(.i-contact-partner__hour-item)) {
    display: none;
  }
}
