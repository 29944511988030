@use "settings/s-colors.scss" as colors;

.u-bg-primary {
  background-color: colors.$primary;
}

.u-bg-primary-100 {
  background-color: colors.$primary-100;
}

.u-bg-secondary-300 {
  background-color: colors.$secondary-300;
}

.u-bg-secondary-400 {
  background-color: colors.$secondary-400;
}

.u-bg-tertiary-300 {
  background-color: colors.$tertiary-300;
}

.u-bg-white {
  background-color: colors.$white;
}

.u-bg-gray {
  background-color: colors.$gray;
}

.u-bg-gradient-primary {
  background: linear-gradient(90deg, colors.$primary-400 0%, colors.$white 100%);
}

.u-bg-gradient-secondary {
  background: linear-gradient(256.08deg, colors.$secondary-400 0%, colors.$white 59.93%);
}
