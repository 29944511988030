@use "settings/s-colors" as colors;
@use "settings/s-fonts.scss" as fonts;

.partner-map {
  padding-top: 64px;
  margin-top: 104px;
  border: 1px solid colors.$secondary-400;

  @include media-breakpoint-down(md) {
    padding: 40px 0 0;
    border: none;
  }
}

.partner-map__form {
  max-width: 801px;
  width: 100%;

  @include media-breakpoint-down(xxl) {
    transform: translate(-50%, calc(-50% - 18px));
  }

  @include media-breakpoint-down(lg) {
    padding-inline: 0;
    max-width: 568px;
  }

  @include media-breakpoint-down(sm) {
    transform: translate(-50%, calc(-50% - 20px));
  }
}

iframe + div {
  border: none !important;
}

#map {
  height: 589px;
  width: 100%;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    height: 512px;
  }

  & .gm-style-mtc > button {
    min-height: 48px !important;
  }

  & .gm-fullscreen-control {
    min-height: 48px !important;
    min-width: 48px !important;
  }

  & .gm-style-iw {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    padding: 16px !important;
    font-size: 16px !important;

    @include media-breakpoint-down(md) {
      padding: 16px 12px !important;
      min-height: 320px !important;
    }
  }

  .gm-style-iw-chr {
    display: none !important;
  }

  .gm-style-iw-tc {
    display: none !important;
  }

  .gm-style-iw-c {
    border-radius: 0 !important;
    box-shadow: 0 8px 24px 0 #30274829 !important;
    overflow-y: auto;
  }

  & .gm-style-iw > div {
    overflow: visible !important;
  }

  & .gm-style-iw button {
    display: none !important;
  }

  & .gm-style {
    font-family: fonts.$primary;
  }

  .bounce {
    animation: bounce 2s;
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }

    40% {
      transform: translateY(-30px);
    }

    60% {
      transform: translateY(-15px);
    }
  }
}

.controls {
  .f-text__wrap {
    border: none;
  }
}

.pac-container {
  display: block;
}
