@use "settings/s-colors" as colors;

.f-textarea {
  --color: #{colors.$text-500};
  --border-color: #{colors.$secondary-400};
  --radius: 0;

  &.--invalid {
    --color: #{colors.$danger};
    --border-color: #{colors.$danger};
  }

  .f-label {
    --top: 20px;
    --translate-y: 0;

    &.--active {
      --top: 0;
      --translate-y: -50%;
    }
  }
}

.f-textarea__wrap {
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
}

.f-textarea__input {
  border-radius: var(--radius);
  color: var(--color);
  transition: color 0.3s ease;
}
