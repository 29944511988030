@use "settings/s-general.scss" as general;
@use "settings/s-fonts.scss" as fonts;
@use "settings/s-colors.scss" as colors;

.entry-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 40px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  h1 {
    margin-top: 0;
    position: relative;
  }

  h2 {
    position: relative;
  }

  & > h2:first-child,
  & > h3:first-child {
    margin-top: 0;
  }
}
