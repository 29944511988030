*, :before, :after {
  box-sizing: border-box;
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: .25;
  margin: .5rem 0;
}

h6, h5, h4, h3, h2, h1 {
  color: var(--bs-heading-color);
  margin-top: 0;
  margin-bottom: .25rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (width >= 75em) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (width >= 75em) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (width >= 75em) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (width >= 75em) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: .875em;
}

mark {
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--bs-secondary-color);
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -.125rem;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

iframe {
  border: 0;
}

[hidden] {
  display: none !important;
}

.container, .container-fluid {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 36em) {
  .container {
    max-width: 33.75rem;
  }
}

@media (width >= 48em) {
  .container {
    max-width: 45rem;
  }
}

@media (width >= 62em) {
  .container {
    max-width: 60rem;
  }
}

@media (width >= 75em) {
  .container {
    max-width: 71.25rem;
  }
}

@media (width >= 93.75em) {
  .container {
    max-width: 91.25rem;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 36rem;
  --bs-breakpoint-md: 48rem;
  --bs-breakpoint-lg: 62rem;
  --bs-breakpoint-xl: 75rem;
  --bs-breakpoint-xxl: 93.75rem;
  --bs-breakpoint-xxxl: 120rem;
}

.row {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  flex: 1 0;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.gx-0 {
  --bs-gutter-x: 0;
}

.gx-1 {
  --bs-gutter-x: .5rem;
}

.gy-2 {
  --bs-gutter-y: 1rem;
}

.gx-4 {
  --bs-gutter-x: 2rem;
}

.gy-4 {
  --bs-gutter-y: 2rem;
}

@media (width >= 36em) {
  .col-sm-6 {
    flex: none;
    width: 50%;
  }
}

@media (width >= 48em) {
  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }
}

@media (width >= 62em) {
  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }
}

@media (width >= 75em) {
  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }
}

@media (width >= 93.75em) {
  .col-xxl-3 {
    flex: none;
    width: 25%;
  }
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.opacity-50 {
  opacity: .5;
}

.overflow-hidden {
  overflow: hidden;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm);
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.start-0 {
  left: 0;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-self-end {
  align-self: flex-end;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: 2.5rem;
}

.mt-auto {
  margin-top: auto;
}

.me-5 {
  margin-right: 2.5rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 2.5rem;
}

.ms-1 {
  margin-left: .5rem;
}

.ms-2 {
  margin-left: 1rem;
}

.ms-auto {
  margin-left: auto;
}

.p-1 {
  padding: .5rem;
}

.p-2 {
  padding: 1rem;
}

.p-3 {
  padding: 1.5rem;
}

.p-4 {
  padding: 2rem;
}

.p-5 {
  padding: 2.5rem;
}

.px-1 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-8 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pt-1 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: 1.5rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pb-3 {
  padding-bottom: 1.5rem;
}

.gap-1 {
  gap: .5rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-3 {
  gap: 1.5rem;
}

.gap-4 {
  gap: 2rem;
}

.gap-5 {
  gap: 2.5rem;
}

.gap-8 {
  gap: 4rem;
}

.row-gap-2 {
  row-gap: 1rem;
}

.row-gap-3 {
  row-gap: 1.5rem;
}

.row-gap-6 {
  row-gap: 3rem;
}

.column-gap-1 {
  column-gap: .5rem;
}

.column-gap-3 {
  column-gap: 1.5rem;
}

.column-gap-4 {
  column-gap: 2rem;
}

.column-gap-5 {
  column-gap: 2.5rem;
}

.text-start {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.text-nowrap {
  white-space: nowrap;
}

.pe-none {
  pointer-events: none;
}

.pe-auto {
  pointer-events: auto;
}

@media (width >= 36em) {
  .d-sm-block {
    display: block;
  }

  .w-sm-auto {
    width: auto;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap;
  }

  .mb-sm-5 {
    margin-bottom: 2.5rem;
  }

  .p-sm-2 {
    padding: 1rem;
  }

  .p-sm-3 {
    padding: 1.5rem;
  }

  .px-sm-2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .gap-sm-2 {
    gap: 1rem;
  }

  .gap-sm-3 {
    gap: 1.5rem;
  }

  .gap-sm-5 {
    gap: 2.5rem;
  }

  .row-gap-sm-4 {
    row-gap: 2rem;
  }
}

@media (width >= 48em) {
  .w-md-auto {
    width: auto;
  }

  .flex-md-row {
    flex-direction: row;
  }

  .justify-content-md-end {
    justify-content: flex-end;
  }

  .align-items-md-center {
    align-items: center;
  }

  .p-md-2 {
    padding: 1rem;
  }

  .p-md-5 {
    padding: 2.5rem;
  }

  .px-md-2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .px-md-4 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .pt-md-3 {
    padding-top: 1.5rem;
  }
}

@media (width >= 62em) {
  .d-lg-block {
    display: block;
  }

  .d-lg-inline-flex {
    display: inline-flex;
  }

  .d-lg-none {
    display: none;
  }

  .ms-lg-0 {
    margin-left: 0;
  }

  .p-lg-0 {
    padding: 0;
  }

  .py-lg-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-lg-1 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .pb-lg-4 {
    padding-bottom: 2rem;
  }
}

.container {
  padding-left: 1.625rem;
  padding-right: 1.625rem;
}

@media (width <= 35.999em) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width <= 359px) {
  .container {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.fancybox__container {
  --fancybox-accent-color: #f2f7f5;
}

.flatpickr-calendar {
  --theme-color: #2f4c4f;
  --theme-color-hover: #f2f7f5;
}

.flatpickr-calendar .flatpickr-day.selected, .flatpickr-calendar .flatpickr-day.selected:hover {
  background: var(--theme-color);
  border-color: var(--theme-color);
}

.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--theme-color);
}

.swiper {
  --swiper-pagination-color: #2f4c4f;
  overflow: hidden;
}

.swiper-wrapper {
  display: flex;
}

body {
  -webkit-font-smoothing: antialiased;
  min-height: calc(var(--vh, 1vh) * 100);
  color: #302748;
  flex-direction: column;
  min-height: 100vh;
  font-family: Archivo, sans-serif;
  line-height: 1.5;
  display: flex;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale !important;
}

@supports (-webkit-touch-callout: none) {
  body {
    min-height: -webkit-fill-available;
  }
}

figure {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  text-wrap: balance;
  margin-bottom: 0;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  scroll-behavior: smooth;
  height: 100%;
  scroll-padding-top: 7rem;
}

@media (width <= 74.999em) {
  html {
    scroll-padding-top: 5.5rem;
  }
}

iframe {
  max-width: 100%;
  display: block;
}

img {
  opacity: 1;
  max-width: 100%;
  height: auto;
  transition: opacity .5s;
  display: block;
}

img[data-src], img[data-srcset] {
  min-width: .0625rem;
  min-height: .0625rem;
  display: block;
}

img[data-src]:not(.loaded) {
  opacity: 0;
}

a {
  color: #302748;
  text-decoration: underline;
  transition: text-decoration-color .3s;
  display: inline;
}

a:hover, a:focus {
  color: #302748;
  text-decoration-color: #0000;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

main {
  flex-grow: 1;
  display: block;
  overflow-x: hidden;
}

p {
  text-wrap: pretty;
}

:root {
  --section-spacing: 7rem;
}

@media (width <= 35.999em) {
  :root {
    --section-spacing: 4rem;
  }
}

strong, b {
  font-weight: 700;
}

h1, h2, h3, h4, button, input, label {
  line-height: 1.1;
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
}

progress {
  vertical-align: baseline;
}

* {
  min-width: 0;
}

label[for] {
  cursor: pointer;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

.entry-content .alignnone {
  margin: .3125rem 1.25rem 1.25rem 0;
}

.entry-content .alignright {
  float: right;
  margin: 0 0 1.25rem 1.875rem;
}

.entry-content .alignright img {
  margin-block: 0;
}

.entry-content .alignleft {
  float: left;
  margin: 0 1.875rem 1.25rem 0;
}

.entry-content .alignleft img {
  margin-block: 0;
}

.entry-content .aligncenter, .entry-content div.aligncenter {
  margin: 3rem auto;
  display: block;
}

.entry-content .aligncenter img, .entry-content div.aligncenter img {
  margin-block: 0;
  margin-inline: auto;
}

@media (width <= 35.999em) {
  .entry-content .alignleft, .entry-content .alignright {
    float: none;
    margin: 0;
    margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.screen-reader-text {
  clip: rect(.0625rem, .0625rem, .0625rem, .0625rem);
  width: .0625rem;
  height: .0625rem;
  overflow: hidden;
  position: absolute !important;
}

.screen-reader-text:focus {
  color: #21759b;
  z-index: 100000;
  background-color: #f1f1f1;
  border-radius: .1875rem;
  width: auto;
  height: auto;
  padding: .9375rem 1.4375rem .875rem;
  font-size: .875rem;
  font-weight: bold;
  line-height: normal;
  text-decoration: none;
  display: block;
  top: .3125rem;
  left: .3125rem;
  box-shadow: 0 0 .125rem .125rem #0009;
  clip: auto !important;
}

.u-bg-primary {
  background-color: #2f4c4f;
}

.u-bg-primary-100 {
  background-color: #e7f0ff;
}

.u-bg-secondary-300 {
  background-color: #f4f5f7;
}

.u-bg-secondary-400 {
  background-color: #e8eaef;
}

.u-bg-tertiary-300 {
  background-color: #f6f8fa;
}

.u-bg-white {
  background-color: #fff;
}

.u-bg-gray {
  background-color: #a9a9b2;
}

.u-bg-gradient-primary {
  background: linear-gradient(90deg, #f2f7f5 0%, #fff 100%);
}

.u-bg-gradient-secondary {
  background: linear-gradient(256.08deg, #e8eaef 0%, #fff 59.93%);
}

.u-clamp {
  --lines: 2;
  -webkit-line-clamp: var(--lines);
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.u-clamp-4 {
  --lines: 4;
}

.u-clamp-1 {
  --lines: 1;
}

.u-color-primary {
  color: #302748;
}

.u-color-primary-muted {
  color: #302748;
  opacity: .5;
}

.u-color-primary-2 {
  color: #2f4c4f;
}

.u-color-primary-400 {
  color: #f2f7f5;
}

.u-color-primary-500 {
  color: #2f4c4f;
}

.u-color-secondary {
  color: #9893a4;
}

.u-color-white {
  color: #fff;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-fz-xs {
  --size: .75rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .5rem);
}

.u-fz-sm {
  --size: .875rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .125rem);
}

.u-fz-md {
  --size: 1rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .5rem);
}

.u-fz-lg {
  --size: 1.125rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .125rem);
}

.u-fz-xl {
  --size: 1.25rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .5rem);
}

.u-fz-xxl {
  --size: 1.5rem;
  font-size: var(--size);
  line-height: var(--size);
}

@media (width <= 35.999em) {
  .u-fz-xxl {
    --size: 1rem;
    font-size: var(--size);
    line-height: calc(var(--size)  + .25rem);
  }
}

.u-fz-xxxl {
  --size: 1.5rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .5rem);
}

@media (width <= 35.999em) {
  .u-fz-xxxl {
    line-height: var(--size);
  }
}

.u-fw-400 {
  font-weight: 400;
}

.u-fw-500 {
  font-weight: 500;
}

.u-fw-600 {
  font-weight: 600;
}

.u-fw-700 {
  font-weight: 700;
}

.u-h1 {
  --size: 4rem;
  --line-height: calc(var(--size)  + .5rem);
}

@media (width <= 47.999em) {
  .u-h1 {
    --size: 2.5rem;
  }
}

.u-h2 {
  --size: 2.5rem;
  --line-height: calc(var(--size)  + .5rem);
}

@media (width <= 47.999em) {
  .u-h2 {
    --size: 2rem;
  }
}

.u-h3 {
  --size: 2rem;
  --line-height: var(--size);
}

@media (width <= 47.999em) {
  .u-h3 {
    --size: 1.5rem;
  }
}

.u-h4 {
  --size: 1.5rem;
  --line-height: var(--size);
}

@media (width <= 47.999em) {
  .u-h4 {
    --size: 1.125rem;
    --line-height: calc(var(--size)  + .125rem);
  }
}

.u-h5 {
  --size: 1rem;
  --line-height: calc(var(--size)  + .25rem);
}

@media (width <= 47.999em) {
  .u-h5 {
    --size: 1rem;
  }
}

.u-h1, .u-h2, .u-h3, .u-h4, .u-h5 {
  font-size: var(--size);
  line-height: var(--line-height);
  font-weight: 700;
}

.u-img-hover {
  backface-visibility: hidden;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.u-img-hover img {
  will-change: transform, opacity;
  transition-property: opacity, transform;
}

.u-img-hover:hover img {
  transform: scale(1.03);
}

.--loading {
  pointer-events: none;
  z-index: 2;
  position: relative;
}

.--loading:before {
  content: "";
  z-index: 9000;
  opacity: .8;
  background-color: #fff;
  width: 100%;
  height: 100%;
  transition: opacity .3s;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.--loading:after {
  content: "";
  z-index: 9001;
  border: .25rem solid #7387ac;
  border-bottom-color: #2f4c4f;
  border-radius: 50%;
  width: 1.875rem;
  height: 1.875rem;
  margin-top: -.9375rem;
  margin-left: -.9375rem;
  animation: 1s cubic-bezier(.68, -.55, .27, 1.55) infinite rotation;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.u-scrollbar .os-scrollbar {
  --os-handle-bg: #302748;
  --os-handle-bg-hover: #302748;
  --os-handle-bg-active: #2f4c4f;
  --os-handle-border-radius: 0;
  --os-size: 1rem;
  --os-track-bg: #f6f8fa;
  --os-track-bg-hover: #f6f8fa;
  --os-track-bg-active: #f6f8fa;
  --os-padding-perpendicular: .375rem;
  --os-padding-axis: .5rem;
  cursor: grab;
}

.u-scrollbar .os-scrollbar:active {
  cursor: grabbing;
}

.u-scrollbar-native {
  --sb-track-color: #f6f8fa;
  --sb-thumb-color: #302748;
  --sb-size: .25rem;
}

.u-scrollbar-native::-webkit-scrollbar {
  width: var(--sb-size);
}

.u-scrollbar-native::-webkit-scrollbar:horizontal {
  height: var(--sb-size);
}

.u-scrollbar-native::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 0;
}

.u-scrollbar-native::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border: .0625rem solid #fff;
  border-radius: 0;
}

@supports not selector(::-webkit-scrollbar) {
  .u-scrollbar-native {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}

.u-posr {
  position: relative;
}

.u-posa {
  position: absolute;
}

.u-fixed {
  position: fixed;
}

.u-sticky {
  position: sticky;
}

.u-top-50-p {
  top: 50%;
}

.u-left-50-p {
  left: 50%;
}

.u-top-0 {
  top: 0;
}

.u-bottom-0 {
  bottom: 0;
}

.u-left-0 {
  left: 0;
}

.u-right-0 {
  right: 0;
}

.u-translate-middle-x {
  transform: translateX(-50%);
}

.u-translate-middle-y {
  transform: translateY(-50%);
}

.u-translate-middle {
  transform: translateX(-50%)translateY(-50%);
}

.u-rounded-full {
  border-radius: 624.938rem;
}

.u-z-index-1 {
  z-index: 1;
}

.u-z-index-2 {
  z-index: 2;
}

.u-z-index-3 {
  z-index: 3;
}

.u-z-index-negative {
  z-index: -1;
}

.u-z-index-10 {
  z-index: 10;
}

.base-header {
  --spacing: 4rem;
  margin-inline: auto;
  margin-bottom: var(--spacing);
  max-width: 47rem;
}

@media (width <= 35.999em) {
  .base-header {
    --spacing: 2rem;
  }
}

.base-header.--left {
  text-align: left;
  align-items: flex-start;
  margin-inline: 0;
}

.base-header.--spacing-none {
  --spacing: 0;
}

.base-header.--spacing-sm {
  --spacing: 1.5rem;
}

.base-header.--spacing-md {
  --spacing: 2rem;
}

@media (width <= 35.999em) {
  .base-header.--spacing-md {
    --spacing: 1rem;
  }
}

.base-header.--spacing-lg {
  --spacing: 2.5rem;
}

.base-header__description p {
  margin-bottom: 0;
}

.c-overline {
  width: 2.5rem;
  height: .1875rem;
}

.base-section {
  --spacing: var(--section-spacing);
  padding-block: var(--spacing);
}

@media (width <= 35.999em) {
  .base-section {
    --spacing: 4rem;
  }
}

.base-section.--top-0 {
  padding-top: 0;
}

.base-section.--bottom-0 {
  padding-bottom: 0;
}

.base-section.--none {
  --spacing: 0;
}

.btn {
  --direction-offset: .5rem;
}

.btn.--size-xs {
  --direction-offset: .25rem;
}

@media (hover: hover) {
  .btn.--animation-right:hover svg {
    transform: translateX(var(--direction-offset));
    transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
  }

  .btn.--animation-left:hover svg {
    transform: translateX(calc(-1 * var(--direction-offset)));
    transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
  }

  .btn.--animation-zoom:hover svg {
    transform: scale(1.1);
  }

  .btn.--animation-down:hover svg {
    transform: translateY(var(--direction-offset));
  }
}

.btn.--lg {
  --font-size: 1rem;
  --padding-y: .75rem;
  --padding-x: 1.5rem;
  --min-height: 3.5rem;
}

.btn.--sm {
  --font-size: .875rem;
  --padding-y: .75rem;
  --padding-x: 1.5rem;
  --min-height: 3rem;
  --svg-size: 1.25rem;
}

.btn.--xs {
  --font-size: .75rem;
  --padding-y: .75rem;
  --padding-x: 1.5rem;
  --min-height: 2.5rem;
  --svg-size: 1.5rem;
}

.btn.--xxs {
  --font-size: .75rem;
  --padding-y: .75rem;
  --padding-x: .75rem;
  --min-height: 2rem;
  --svg-size: 1rem;
}

.btn.--primary {
  --background: #89001c;
  background-color: var(--background);
  will-change: background-color;
  transition: background-color .3s;
}

@media (hover: hover) {
  .btn.--primary:hover {
    --background: #302748;
  }
}

.btn.--secondary {
  --background: #f4f5f7;
  background-color: var(--background);
  will-change: color, background-color;
  transition: color .3s, background-color .3s;
}

@media (hover: hover) {
  .btn.--secondary:hover {
    --background: transparent;
  }
}

.btn.--outline {
  --font-color: #89001c;
  --border-color: #e8eaef;
  --background: #fff;
  --polygon-size: 0;
  background-color: var(--background);
  will-change: background-color;
  transition: background-color .3s;
}

.btn.--outline:before {
  content: "";
  border: .0625rem solid var(--border-color);
  z-index: -2;
  position: absolute;
  inset: 0;
}

.btn.--outline:after {
  content: "";
  background-color: var(--background);
  z-index: -1;
  clip-path: polygon(0% 0%, 100% 0, 100% calc(100% - var(--polygon-size)), calc(100% - var(--polygon-size)) 100%, 0% 100%);
  transition: background-color .3s;
  position: absolute;
  inset: .0625rem;
}

@media (hover: hover) {
  .btn.--outline:hover {
    --background: #e8eaef80;
  }
}

.btn.--ghost {
  --background: transparent;
  --font-color: #89001c;
  background-color: var(--background);
  will-change: background-color;
  transition: background-color .3s;
}

@media (hover: hover) {
  .btn.--ghost:hover {
    --background: #f2f7f5;
  }
}

.btn__icon .icon {
  --size: var(--svg-size);
}

.btn__icon svg {
  will-change: transform;
  transition: transform .3s;
}

.btn {
  --border-radius: 0;
  --font-size: 1.125rem;
  --font-weight: 600;
  --font-color: #fff;
  --font-family: Archivo, sans-serif;
  --padding-y: 1rem;
  --padding-x: 1.5rem;
  --min-height: 4rem;
  --min-width: 13.6875rem;
  --svg-size: 1rem;
  --polygon-size: 1rem;
  font-size: var(--font-size);
  font-family: var(--font-family);
  color: var(--font-color);
  font-weight: var(--font-weight);
  border-radius: var(--border-radius);
  padding-inline: var(--padding-x);
  padding-block: var(--padding-y);
  min-height: var(--min-height);
  min-width: var(--min-width);
  clip-path: polygon(0% 0%, 100% 0, 100% calc(100% - var(--polygon-size)), calc(100% - var(--polygon-size)) 100%, 0% 100%);
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  touch-action: manipulation;
  border: none;
  text-decoration: none;
}

.btn:hover {
  color: var(--font-color);
  text-decoration: none;
}

.btn:focus, .btn:active {
  color: var(--font-color);
  outline: 0;
  text-decoration: none;
}

.btn path {
  fill: var(--font-color);
}

.btn.--icon-before .btn__icon {
  order: -1;
}

.btn.--icon-only {
  --polygon-size: .5rem;
  --min-width: auto;
  width: var(--min-height);
  height: var(--min-height);
  justify-content: center;
  align-items: center;
  min-height: auto;
  padding: 0;
  display: flex;
}

.btn.--icon-only .btn__title {
  display: none;
}

@media (width <= 35.999em) {
  .btn.--full-width-mobile {
    width: 100%;
  }

  .btn {
    --min-height: 3.5rem;
    --padding-y: .75rem;
  }
}

@media (width >= 75em) {
  .content-w-aside__content {
    grid-column: 1 / span 8;
  }
}

.content-w-aside__aside {
  row-gap: 4rem;
}

@media (width >= 75em) {
  .content-w-aside__aside {
    grid-column: span 4;
  }
}

@media (width <= 35.999em) {
  .content-w-aside__aside {
    row-gap: 2rem;
  }
}

.content-w-aside {
  --padding-y: 4rem;
  padding-block: var(--padding-y);
}

@media (width <= 35.999em) {
  .content-w-aside {
    --padding-y: 2rem;
  }
}

.content-w-aside.--top-0 {
  padding-top: 0;
}

.content-w-aside.--none {
  --padding-y: 0;
}

.content-w-aside.--reverse .content-w-aside__aside {
  order: -1;
  grid-column: span 3;
}

.content-w-aside.--reverse .content-w-aside__content {
  grid-column: 4 / span 9;
}

.content-w-aside__row {
  --gap: 2rem;
  gap: var(--gap);
}

@media (width <= 74.999em) {
  .content-w-aside__row {
    --gap: 4rem;
  }
}

@media (width <= 35.999em) {
  .content-w-aside__row {
    --gap: 2rem;
  }
}

@media (width >= 75em) {
  .content-w-aside__row {
    grid-template-columns: repeat(12, 1fr);
    justify-content: center;
    display: grid;
  }
}

@media (width <= 74.999em) {
  .content-w-aside__row {
    flex-direction: column;
    display: flex;
  }
}

.f-checkbox__visual {
  width: var(--size);
  height: var(--size);
  border: .0625rem solid var(--color);
  border-radius: var(--radius);
  background-color: #fff;
  transition: background-color .3s;
}

.f-checkbox__visual svg {
  opacity: 0;
  transition: opacity .3s, transform .3s;
  transform: scale(.2);
}

.f-checkbox__visual:after {
  content: "";
  width: calc(var(--size) / 2);
  height: calc(var(--size) / 2);
  border-radius: calc(var(--size) / 2);
  opacity: 0;
  background-color: #fff;
  transition: opacity .3s, transform .3s;
  display: none;
  position: absolute;
  transform: scale(.2);
}

.f-checkbox__input {
  left: calc(var(--size) / 2);
  top: calc(var(--size) / 2);
  pointer-events: none;
  opacity: 0;
  transform: translateX(-50%);
}

.f-checkbox__input:checked ~ .f-checkbox__visual {
  background-color: var(--color);
}

.f-checkbox__input:checked ~ .f-checkbox__visual:after, .f-checkbox__input:checked ~ .f-checkbox__visual svg {
  opacity: 1;
  transform: scale(1);
}

.f-checkbox__title a {
  color: var(--color);
  font-weight: 700;
}

.f-checkbox {
  --color: #2f4c4f;
  --radius: .25rem;
  --size: 1.5rem;
  cursor: pointer;
}

.f-checkbox.--disabled {
  --color: #a9a9b2;
}

.f-checkbox.--disabled .f-checkbox__title {
  color: var(--color);
}

.f-checkbox.--radio {
  --radius: calc(var(--size) / 2);
}

.f-checkbox.--radio .f-checkbox__visual:after {
  display: block;
}

.f-checkbox-select__header {
  -webkit-user-select: none;
  user-select: none;
  background-color: var(--header-background);
  border: .0625rem solid #ebebeb;
  min-height: 3rem;
  padding-block: .5rem;
}

.f-checkbox-select__clear {
  display: none;
}

.f-checkbox-select__clear.--active {
  display: inline-flex;
}

.f-checkbox-select__toggler {
  width: 2rem;
  height: 2rem;
  transition: background-color .3s;
}

.f-checkbox-select__toggler svg {
  transform: scale(var(--toggler-svg-scale));
  width: .875rem;
  height: .875rem;
  transition: scale .3s;
}

.f-checkbox-select__toggler:hover {
  background-color: #ebebeb;
}

.f-checkbox-select__label {
  --end: 0;
  flex: 1;
  height: 2rem;
  top: 0;
  left: 0;
  overflow: hidden;
}

.f-checkbox-select__label > span {
  white-space: nowrap;
  transition: left 2s linear;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.f-checkbox-select__label:after {
  content: "";
  pointer-events: none;
  background: transparent linear-gradient(270deg, var(--header-background) 0%, #fff0 100%) 0% 0% no-repeat padding-box;
  z-index: 1;
  width: 1rem;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.f-checkbox-select__label:hover > span {
  left: var(--end);
}

.f-checkbox-select__body {
  z-index: 5;
  transform: var(--body-transform);
  transform-origin: top;
  pointer-events: var(--body-pointer-events);
  opacity: var(--body-opacity);
  background-color: #fff;
  border: .0625rem solid #a9a9b2;
  width: calc(100% - .0625rem);
  transition: opacity .3s, transform .3s;
  top: calc(100% - .0625rem);
  left: 50%;
}

.f-checkbox-select__body-inner {
  max-height: 10rem;
}

.f-checkbox-select {
  --header-background: #fff;
  --body-opacity: 0;
  --body-pointer-events: none;
  --body-transform: translateX(-50%) scaleY(.25);
  --toggler-svg-scale: 1;
  min-width: 0;
}

.f-checkbox-select.--active {
  --header-background: #e7f0ff;
  --body-opacity: 1;
  --body-pointer-events: auto;
  --body-transform: translateX(-50%) scaleY(1);
  --toggler-svg-scale: -1;
}

.f-date__icon {
  display: var(--icon-display);
  pointer-events: none;
  padding: .5rem;
  padding-right: 1rem;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateX(0)translateY(-50%);
}

.f-date__input {
  border-radius: var(--radius);
  cursor: var(--cursor);
}

.f-date__input::-webkit-inner-spin-button {
  appearance: none;
  display: none;
}

.f-date__input::-webkit-clear-button {
  appearance: none;
  display: none;
}

.f-date__input::-webkit-calendar-picker-indicator {
  appearance: none;
  display: none;
}

.f-date {
  --border-color: #a9a9b2;
  --radius: .25rem;
  --icon-display: block;
  --cursor: pointer;
}

.f-date:not(.--native) .f-date__input {
  padding-right: 3rem;
}

.f-date.--native {
  --icon-display: none;
  --cursor: auto;
}

.f-date.--invalid {
  --border-color: #d8092e;
}

.f-date__wrap {
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  transition: border-color .3s;
}

.f-error {
  --display: none;
  display: var(--display);
  z-index: 2;
  right: 1.5rem;
}

.f-error.--show {
  --display: flex;
}

.f-base {
  color: #302748;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  width: 100%;
  min-height: 3.5rem;
  padding: .625rem 1.5rem;
  font-weight: 500;
  display: block;
}

.f-base:focus {
  border-color: none;
  outline: none;
}

.f-base::placeholder {
  opacity: 1;
  color: #302748;
}

textarea.f-base {
  min-height: 10.5rem;
  padding: 1.25rem 1rem 1rem 1.5rem;
}

.i-selected-file {
  cursor: pointer;
  background-color: #e7f0ff;
  min-height: 1.5rem;
}

.i-selected-file__remove {
  justify-content: center;
  align-items: center;
  width: .75rem;
  height: .75rem;
  display: flex;
}

.i-selected-file__remove svg {
  width: 100%;
  height: 100%;
}

.f-file.--empty .f-file__files, .f-file__input {
  display: none;
}

.f-label {
  --top: 50%;
  --translate-y: -50%;
  --color: #302748;
  top: var(--top);
  transform: translateY(var(--translate-y));
  color: var(--color);
  pointer-events: none;
  z-index: 2;
  background-color: #fff;
  margin-right: .0625rem;
  padding-inline: .5rem;
  transition: transform .3s, color .3s, font-size .3s, top .3s, left .3s;
  position: absolute;
  left: 1rem;
}

.f-label.--required:after {
  content: "*";
  color: var(--color);
  padding-left: .125rem;
}

.f-label.--active {
  --top: 0;
  font-size: .75rem;
  line-height: 1.25rem;
}

@media (width <= 93.749em) {
  .f-single-select {
    flex-direction: column;
    align-items: flex-start;
  }
}

.f-single-select__select.slim-select.ss-main {
  padding-inline: var(--padding-inline);
  background-color: #e8eaef;
  transition: border .3s;
}

.f-single-select__select.slim-select.ss-main:after {
  content: "";
  pointer-events: none;
  background-image: url("chevron-down-select.1cfa2c0b.svg");
  background-repeat: no-repeat;
  grid-area: select;
  place-self: center end;
  width: 1rem;
  height: 1rem;
  transition: scale .3s;
  display: block;
  scale: 1;
}

.f-single-select__select.slim-select.ss-values.ss-single {
  margin: 0;
}

.f-single-select__select.slim-select.ss-content {
  position: absolute;
}

.f-single-select__select.slim-select .ss-list {
  padding: .5rem;
}

.f-single-select__select.slim-select .ss-list .ss-option {
  width: calc(100% - .5rem);
  padding-block: .5rem;
  padding-inline: 1rem;
  transition: background-color .3s;
}

.f-single-select__select.slim-select .ss-list .ss-option:hover {
  color: #302748;
  background-color: #f6f8fa;
}

@media (width <= 35.999em) {
  .f-single-select__select.slim-select .ss-list .ss-option {
    padding-inline: .5rem;
  }
}

.f-single-select__select.slim-select .ss-arrow {
  display: none;
}

.f-single-select, .f-single-select__select.slim-select {
  --radius: 0;
  --height: 3.5rem;
  --padding-inline: 1.5rem;
  --padding-block: .625rem;
  --border-color: transparent;
  --ss-primary-color: #302748;
  --ss-bg-color: #fff;
  --ss-font-color: #302748;
  --ss-font-placeholder-color: #302748;
  --ss-disabled-color: #dcdee2;
  --ss-border-color: var(--border-color);
  --ss-error-color: colors. $ danger;
  --ss-main-height: var(--height);
  --ss-content-height: 16rem;
  --ss-spacing-l: .4375rem;
  --ss-spacing-m: .75rem;
  --ss-spacing-s: 0;
  --ss-animation-timing: .2s;
  --ss-border-radius: var(--radius);
  --ss-focus-color: transparent;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.f-single-select:hover, .f-single-select__select.slim-select:hover {
  --border-color: #302748;
}

.f-single-select.--native .f-single-select__wrap, .f-single-select__select.slim-select.--native .f-single-select__wrap {
  grid-template-areas: "select";
  align-items: center;
  display: grid;
}

.f-single-select.--native .f-single-select__wrap:after, .f-single-select__select.slim-select.--native .f-single-select__wrap:after {
  content: "";
  pointer-events: none;
  background-image: url("chevron-down-select.1cfa2c0b.svg");
  background-repeat: no-repeat;
  grid-area: select;
  justify-self: end;
  width: 1rem;
  height: 1rem;
  margin-right: 1.5rem;
  display: block;
}

@media (width <= 35.999em) {
  .f-single-select.--native .f-single-select__wrap:after, .f-single-select__select.slim-select.--native .f-single-select__wrap:after {
    margin-right: 1rem;
  }
}

.f-single-select.--native .f-single-select__select, .f-single-select__select.slim-select.--native .f-single-select__select {
  color: #302748;
  border-radius: var(--radius);
  min-height: var(--height);
  border: .0625rem solid var(--border-color);
  padding-block: var(--padding-block);
  padding-inline: var(--padding-inline);
  cursor: pointer;
  appearance: none;
  background-color: #e8eaef;
  outline: none;
  grid-area: select;
  width: 100%;
  margin: 0;
  padding-right: 2.5rem;
  font-weight: 500;
  transition: border .3s;
}

.f-single-select.ss-open-below, .f-single-select__select.slim-select.ss-open-below {
  --border-color: #302748;
}

.f-single-select.ss-open-below:after, .f-single-select__select.slim-select.ss-open-below:after {
  scale: -1;
}

.f-single-select.--invalid, .f-single-select__select.slim-select.--invalid, .f-single-select.--invalid .ss-main, .f-single-select__select.slim-select.--invalid .ss-main {
  --border-color: #d8092e;
}

@media (width <= 93.749em) {
  .f-single-select, .f-single-select__select.slim-select {
    --height: 3rem;
    --padding-inline: 1rem;
  }
}

@media (width <= 35.999em) {
  .f-single-select__label {
    font-size: 1rem;
  }
}

.f-switch {
  --width: 3rem;
  --height: 1.5rem;
  --offset: .25rem;
  --background: #7387ac;
  --background-checked: #2f4c4f;
  --transition-timing: .2s;
  --border-radius: calc(var(--height) / 2);
  --handle-size: calc(var(--height)  - var(--offset) * 2);
  --handle-radius: calc(var(--handle-size) / 2);
  --handle-background: #fff;
  --handle-translate-x: var(--offset);
  --handle-translate-x-checked: calc(var(--width)  - var(--handle-size)  - var(--offset));
}

.f-switch.--disabled {
  --background: #a9a9b2;
  --background-checked: #a9a9b2;
  --handle-background: #ebebeb;
}

.f-switch__visual {
  width: var(--width);
  height: var(--height);
  border-radius: var(--border-radius);
  background-color: var(--background);
  transition: background-color var(--transition-timing) ease;
  will-change: background-color;
}

.f-switch__visual:after {
  content: "";
  transform: translateY(-50%) translateX(var(--handle-translate-x));
  width: var(--handle-size);
  height: var(--handle-size);
  border-radius: var(--handle-radius);
  background-color: var(--handle-background);
  z-index: 2;
  transition: transform var(--transition-timing) ease;
  will-change: transform;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}

.f-switch__input {
  left: 0;
  top: calc(var(--height) / 2);
  pointer-events: none;
  opacity: 0;
}

.f-switch__input:checked ~ .f-switch__visual {
  --background: var(--background-checked);
  --handle-translate-x: var(--handle-translate-x-checked);
}

.f-text__icon {
  pointer-events: none;
  padding: .5rem;
  position: absolute;
}

.f-text__icon.--before {
  padding-left: 1rem;
  top: 50%;
  left: 0;
  transform: translateX(0)translateY(-50%);
}

.f-text__icon.--after {
  padding-right: 1rem;
  top: 50%;
  right: 0;
  transform: translateX(0)translateY(-50%);
}

.f-text__input {
  border-radius: var(--radius);
  color: var(--color);
  transition: color .3s;
}

.f-text {
  --color: #302748;
  --border-color: #e8eaef;
  --radius: 0;
}

.f-text.--active {
  --border-color: #302748;
}

.f-text.--invalid {
  --color: #d8092e;
  --border-color: #d8092e;
}

.f-text.--invalid .f-label {
  --color: #d8092e;
}

.f-text.--icon-before .f-text__input {
  padding-left: 3rem;
}

.f-text.--icon-after .f-text__input {
  padding-right: 3rem;
}

.f-text__wrap {
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  transition: border-color .3s;
}

.f-text__toggle {
  --on-opacity: 0;
  --off-opacity: 1;
  --size: 1.5rem;
  width: var(--size);
  height: var(--size);
  right: 1rem;
}

.f-text__toggle.--active {
  --on-opacity: 1;
  --off-opacity: 0;
}

.f-text__toggle-icon {
  width: var(--size);
  height: var(--size);
  grid-row-start: 1;
  grid-column-start: 1;
}

.f-text__toggle-icon.--on {
  opacity: var(--on-opacity);
}

.f-text__toggle-icon.--off {
  opacity: var(--off-opacity);
}

.f-textarea {
  --color: #302748;
  --border-color: #e8eaef;
  --radius: 0;
}

.f-textarea.--invalid {
  --color: #d8092e;
  --border-color: #d8092e;
}

.f-textarea .f-label {
  --top: 1.25rem;
  --translate-y: 0;
}

.f-textarea .f-label.--active {
  --top: 0;
  --translate-y: -50%;
}

.f-textarea__wrap {
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
}

.f-textarea__input {
  border-radius: var(--radius);
  color: var(--color);
  transition: color .3s;
}

.f-contact {
  --padding: 1.5rem;
  padding: var(--padding);
  border: .5rem solid #2f4c4f;
}

@media (width >= 48em) {
  .f-contact {
    --padding: 2.5rem;
  }
}

@media (width >= 62em) {
  .f-contact__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (width >= 36em) {
  .f-contact__submit {
    margin-left: auto;
  }
}

@media (width <= 35.999em) {
  .f-contact__submit {
    width: 100%;
  }
}

.f-contact__terms a {
  color: #89001c;
  font-weight: 500;
}

.f-newsletter {
  background-color: #e7f0ff;
  border-radius: 1rem;
}

@media (width >= 62em) {
  .f-newsletter__grid {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.f-newsletter__header {
  gap: .25rem;
}

@media (width <= 35.999em) {
  .f-newsletter__inputs-col {
    flex-direction: column;
  }
}

@media (width <= 47.999em) {
  .f-newsletter__submit {
    width: 100%;
  }
}

.g-base {
  flex-wrap: wrap;
  display: flex;
}

.g-base.--gap-xs {
  gap: .25rem;
}

.g-base.--gap-sm {
  gap: .5rem;
}

.g-base.--gap-md {
  gap: 1rem;
}

.g-base.--gap-lg {
  gap: 1.5rem;
}

.g-base.--gap-xl {
  gap: 2rem;
}

.g-base.--gap-xxl {
  gap: 2.5rem;
}

.g-base.--gap-none {
  gap: 0;
}

.g-base.--align-left {
  justify-content: flex-start;
}

.g-base.--align-right {
  justify-content: flex-end;
}

.g-base.--align-center {
  justify-content: center;
}

.g-base.--align-between {
  justify-content: space-between;
}

.g-buttons {
  --spacing: 4rem;
  margin-top: var(--spacing);
}

@media (width <= 35.999em) {
  .g-buttons {
    --spacing: 1.5rem;
  }
}

.g-buttons.--spacing-sm {
  --spacing: 2.5rem;
}

.g-buttons.--spacing-md {
  --spacing: 3rem;
}

@media (width <= 35.999em) {
  .g-buttons.--spacing-md {
    --spacing: 1.5rem;
  }
}

.g-buttons.--spacing-none {
  --spacing: 0;
}

@media (width <= 74.999em) {
  .g-contact-partner {
    width: 100%;
    max-width: 45rem;
    margin-inline: auto;
  }
}

@media (width <= 61.999em) {
  .g-contact-partner {
    max-width: 22.5rem;
  }
}

@media (width >= 75em) {
  .g-events {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

@media (width >= 48em) {
  .g-gallery-slider__wrapper {
    width: 5.5rem;
    height: auto;
    max-height: 100%;
    position: absolute;
    top: .5rem;
    bottom: .5rem;
    left: .5rem;
  }
}

@media (width <= 47.999em) {
  .g-gallery-slider__wrapper {
    order: 2;
  }
}

.g-gallery-slider__wrapper .swiper-slide {
  opacity: 1;
  width: 5.5rem;
}

.g-gallery-slider__wrapper .swiper {
  width: 100%;
  max-height: 100%;
  position: static;
}

.g-gallery-slider__large-img {
  max-width: 35.5rem;
}

@media (width >= 48em) {
  .g-gallery-slider__large-img {
    margin-left: 6.5rem;
  }
}

.g-gallery-slider__body:before {
  content: "";
  pointer-events: none;
  z-index: -1;
  border: .0625rem solid #e8eaef;
  position: absolute;
  inset: 0;
}

.g-gallery-slider__body:after {
  content: "";
  pointer-events: none;
  background-color: #e8eaef;
  width: .0625rem;
  height: calc(100% - 1rem);
  position: absolute;
  top: 50%;
  left: 6.5rem;
  transform: translate(-50%, -50%);
}

@media (width <= 47.999em) {
  .g-gallery-slider__body:after {
    display: none;
  }
}

.swiper-slide-thumb-active .i-gallery-slide {
  --border-color: #89001c;
}

div.fancybox__nav button.carousel__button {
  --font-color: #89001c;
  --border-color: #e8eaef;
  border: .0625rem solid var(--border-color);
  background-color: #fff;
  border-radius: 0;
  transition: all .6s;
  overflow: hidden;
}

@media (hover: hover) {
  div.fancybox__nav button.carousel__button:hover {
    --font-color: #fff;
  }
}

div.fancybox__nav button.carousel__button svg {
  display: none;
}

div.fancybox__nav button.carousel__button:before {
  content: "";
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  background-image: url("chevron-right-fancybox.62bff637.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  transition: background-image .3s, transform .3s;
  position: absolute;
}

@media (hover: hover) {
  div.fancybox__nav button.carousel__button.is-next:hover:before {
    transform: translateX(.3125rem);
  }
}

div.fancybox__nav button.carousel__button.is-prev:before {
  transform: rotate(-180deg);
}

@media (hover: hover) {
  div.fancybox__nav button.carousel__button.is-prev:hover:before {
    transform: translateX(-.3125rem)rotate(-180deg);
  }
}

div.fancybox__backdrop {
  background-color: #002c5b66;
}

div.fancybox__thumb {
  border-radius: 0;
}

div.fancybox__thumb:after {
  border-width: .1875rem !important;
  border-color: #89001c !important;
  border-radius: 0 !important;
}

.g-hero-slider__button {
  --offset: 50%;
  pointer-events: auto;
}

.g-hero-slider__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}

.g-hero-slider__button.--next {
  transform: translateX(var(--offset));
}

@media (width <= 35.999em) {
  .g-hero-slider__button {
    --offset: .5rem;
  }
}

.g-hero-slider__pagination.swiper-pagination {
  --swiper-pagination-bullet-horizontal-gap: 0;
  --swiper-pagination-bullet-border-radius: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  min-height: .5rem;
  margin-top: .5rem;
  display: flex;
  position: static;
}

.g-hero-slider__pagination.swiper-pagination .swiper-pagination-bullet {
  transform-origin: center;
  transition: width .3s;
}

.g-hero-slider__pagination.swiper-pagination .swiper-pagination-bullet-active {
  --swiper-pagination-bullet-width: 2rem;
}

.g-hero-slider .swiper-slide {
  backface-visibility: hidden;
  flex-shrink: 0;
  transform: translate3d(0, 0, 0);
  width: 100% !important;
}

.g-hero-slider__buttons {
  pointer-events: none;
}

.g-jobs {
  gap: 5rem;
  width: 100%;
  max-width: 58rem;
  margin: 0 auto;
}

.g-jobs.--card {
  max-width: unset;
  flex-flow: wrap;
  justify-content: center;
  gap: 2rem;
  display: flex;
}

.g-partner.--hidden {
  display: none;
}

@media (width <= 74.999em) {
  .g-partner {
    width: 100%;
    max-width: 45rem;
    margin-inline: auto;
  }
}

@media (width <= 61.999em) {
  .g-partner {
    max-width: 22.5rem;
  }
}

.g-posts {
  grid-template-columns: repeat(3, 1fr);
  gap: 3.5rem 2rem;
  display: grid;
}

@media (width <= 61.999em) {
  .g-posts {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 47.999em) {
  .g-posts {
    flex-direction: column;
    display: flex;
  }
}

.g-posts.--compact {
  grid-template-columns: repeat(2, 1fr);
}

@media (width <= 47.999em) {
  .g-posts.--compact {
    flex-direction: column;
    display: flex;
  }
}

.g-posts.--inline {
  flex-direction: column;
  display: flex;
}

.g-pricetable {
  overflow-x: auto;
}

.g-pricetable__row:nth-child(odd) {
  background-color: #e7f0ff;
}

.g-pricetable__cell .icon {
  margin: 0 auto;
}

@media (width <= 47.999em) {
  .g-product {
    max-width: 22.5rem;
    margin-inline: auto;
  }
}

@media (width <= 392px) {
  .g-product {
    max-width: 100%;
  }

  .g-product__cell {
    margin-inline: 0;
    padding-inline: 0;
  }
}

.g-product-slider__button {
  --offset: calc(50% - .25rem);
  pointer-events: auto;
}

.g-product-slider__button.btn.--secondary {
  --background: #e8eaef;
  --min-height: 4rem;
  --polygon-size: 1rem;
  --svg-size: 1rem;
  border: .1875rem solid #fff;
}

@media (hover: hover) {
  .g-product-slider__button.btn.--secondary:hover {
    --background: #e8eaef;
  }
}

@media (width <= 359px) {
  .g-product-slider__button.btn.--secondary {
    --min-height: 2rem;
    --offset: calc(50% - .75rem);
  }
}

.g-product-slider__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
  clip-path: polygon(0 0, 100% 0, 100% 100%, 1rem 100%, 0 calc(100% - var(--polygon-size)));
}

.g-product-slider__button.--next {
  transform: translateX(var(--offset));
}

.g-product-slider__button .icon.--lg {
  --size: 1.25rem;
}

.g-product-slider.--loading:before {
  border-radius: 2rem;
}

.g-product-slider.swiper {
  overflow: initial;
  align-items: center;
  display: flex;
}

.g-product-slider .swiper-wrapper {
  align-items: center;
  display: flex;
}

.g-product-slider .swiper-slide {
  backface-visibility: hidden;
  opacity: 0;
  pointer-events: none;
  flex-shrink: 0;
  transition: opacity .3s;
  transform: translate3d(0, 0, 0);
}

.g-product-slider .swiper-slide-visible {
  opacity: 1;
  pointer-events: auto;
}

.g-product-slider__buttons {
  pointer-events: none;
}

.g-product-slider__buttons .swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

@media (width >= 62em) {
  .g-signpost__row {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.g-steps {
  row-gap: 4.5rem;
}

.g-steps.--vertical {
  max-width: 42.875rem;
  margin-inline: auto;
}

.g-testimonials__button {
  --offset: 50%;
  pointer-events: auto;
}

.g-testimonials__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}

.g-testimonials__button.--next {
  transform: translateX(var(--offset));
}

@media (width <= 35.999em) {
  .g-testimonials__button {
    --offset: .5rem;
  }
}

.g-testimonials__pagination.swiper-pagination {
  --swiper-pagination-bullet-horizontal-gap: 0;
  --swiper-pagination-bullet-border-radius: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  min-height: .5rem;
  margin-top: .5rem;
  display: flex;
  position: static;
}

.g-testimonials__pagination.swiper-pagination .swiper-pagination-bullet {
  transform-origin: center;
  transition: width .3s;
}

.g-testimonials__pagination.swiper-pagination .swiper-pagination-bullet-active {
  --swiper-pagination-bullet-width: 2rem;
}

.g-testimonials .swiper-slide {
  backface-visibility: hidden;
  flex-shrink: 0;
  width: fit-content;
  transform: translate3d(0, 0, 0);
}

.g-testimonials .swiper-wrapper {
  align-items: center;
}

.g-testimonials__buttons {
  pointer-events: none;
}

.g-usps .row {
  row-gap: 4.5rem;
  margin: 0 -.3125rem;
  padding-block: 3.5rem;
}

@media (width <= 47.999em) {
  .g-usps .row {
    padding-block: 2rem;
  }
}

@media (width <= 35.999em) {
  .g-usps .row {
    row-gap: 1.5rem;
  }
}

.g-usps.--product .row {
  margin: 0 -.8125rem;
}

.g-usps.--product .g-usps__cell {
  padding-inline: .75rem;
}

@media (width <= 35.999em) {
  .g-usps.--product .g-usps__cell:nth-child(2n) .i-usp {
    padding-left: 1rem;
  }

  .g-usps.--product .g-usps__cell:nth-child(odd) .i-usp {
    padding-right: 1rem;
  }
}

.icon {
  --size: 1.25rem;
  color: #081828;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
}

.icon svg {
  width: 100%;
  height: 100%;
}

.icon.--colored {
  color: var(--color);
}

.icon.--colored path {
  fill: var(--color);
  transition: fill .3s;
}

.icon.--primary {
  --color: #2f4c4f;
}

.icon.--primary-text {
  --color: #302748;
}

.icon.--primary-400 {
  --color: #f2f7f5;
}

.icon.--primary-500 {
  --color: #2f4c4f;
}

.icon.--secondary {
  --color: #e8eaef;
}

.icon.--conversion {
  --color: #89001c;
}

.icon.--white {
  --color: #fff;
}

.icon.--black {
  --color: #081828;
}

.icon.--danger {
  --color: #d8092e;
}

.icon.--4xl {
  --size: 10.125rem;
}

.icon.--xxxl {
  --size: 3rem;
}

.icon.--xxl {
  --size: 2.5rem;
}

.icon.--xl {
  --size: 2rem;
}

.icon.--lg {
  --size: 1.5rem;
}

.icon.--md {
  --size: 1.25rem;
}

.icon.--sm {
  --size: 1rem;
}

.icon.--xs {
  --size: .625rem;
}

.icon.--xxs {
  --size: .375rem;
}

.icon.--unset {
  --size: unset;
}

.img {
  overflow: hidden;
}

.img.--ratio-4-3 {
  aspect-ratio: 4 / 3;
}

.img.--ratio-16-9 {
  aspect-ratio: 16 / 9;
}

.img.--ratio-3-2 {
  aspect-ratio: 3 / 2;
}

.img.--ratio-1-1 {
  aspect-ratio: 1;
}

.img.--ratio-3-4 {
  aspect-ratio: 3 / 4;
}

.img.--contain {
  width: 100%;
  height: 100%;
}

.img.--contain img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.img.--contain picture, .img.--cover {
  width: 100%;
  height: 100%;
}

.img.--cover img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img.--cover picture {
  width: 100%;
  height: 100%;
}

.img.--placeholder {
  position: relative;
}

.img.--placeholder:before {
  content: "";
  z-index: -1;
  background-color: #ebebeb;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.i-alert {
  --background: #fff;
  --border-color: #ebebeb;
  --color: #081828;
  --opacity: 0;
  --translate: -2rem;
  --icon-background: #fff;
  background-color: var(--background);
  color: var(--color);
  opacity: var(--opacity);
  border-color: var(--border-color);
  transform: translateY(var(--translate));
  pointer-events: none;
  will-change: opacity, transform;
  border-style: solid;
  border-width: .0625rem;
  border-radius: .25rem;
  transition: opacity .3s, transform .3s;
}

.i-alert.--show {
  --opacity: 1;
  --translate: 0;
  pointer-events: auto;
}

.i-alert.--success {
  --border-color: #48a463;
  --background: #e7f4e4;
  --color: #48a463;
  --icon-background: #48a463;
}

.i-alert.--danger {
  --border-color: #d8092e;
  --background: #f9dede;
  --color: #d8092e;
  --icon-background: #d8092e;
}

.i-alert.--warning {
  --border-color: #fdb81e;
  --background: #fff1d2;
  --color: #fdb81e;
  --icon-background: #fdb81e;
}

.i-alert.--primary {
  --border-color: #2f4c4f;
  --background: #2f4c4f;
  --color: #fff;
  --icon-background: #2f4c4f;
}

.i-alert__icon, .i-alert__close {
  background-color: var(--icon-background);
}

.i-author__description p {
  margin-bottom: 0;
}

.i-author {
  display: flex;
}

.i-author.--compact {
  align-items: center;
}

.i-author.--compact .i-author__description {
  display: none;
}

@media (width <= 35.999em) {
  .i-author {
    flex-direction: column;
  }
}

.i-author__image {
  --size: 4rem;
  width: var(--size);
  height: var(--size);
  max-width: var(--size);
}

.i-banner {
  background: radial-gradient(49.01% 570.7% at 0 100%, #13282b 0%, #2f4c4f 100%);
  border: .5rem solid #2f4c4f;
  padding: 3rem 4rem 3rem 18.5rem;
}

@media (width >= 62em) {
  .i-banner {
    margin-block: 4rem;
  }
}

@media (width <= 93.749em) {
  .i-banner {
    padding-block: 1.5rem;
  }
}

@media (width <= 74.999em) {
  .i-banner {
    padding: 1.5rem 2.5rem 1.5rem 16.5rem;
  }
}

@media (width <= 61.999em) {
  .i-banner {
    padding: 1rem;
  }
}

.i-banner__header {
  max-width: 44.5rem;
}

@media (width <= 93.749em) {
  .i-banner__header {
    max-width: 26.5rem;
  }
}

@media (width <= 74.999em) {
  .i-banner__header {
    max-width: 100%;
  }
}

.i-banner__img {
  max-width: 12.5rem;
  height: auto;
  left: 2rem;
  transform: translateY(.25rem);
}

@media (width <= 61.999em) {
  .i-banner__img {
    display: none;
  }
}

.i-banner__decoration {
  transform: translate(-4.5rem, 4.5rem);
}

@media (width <= 61.999em) {
  .i-banner__decoration {
    max-width: 6rem;
    max-height: 6rem;
    transform: translate(-2.5rem, 2.5rem);
  }
}

@media (width <= 35.999em) {
  .i-banner__buttons {
    width: 100%;
  }
}

.i-breadcrumb.--homepage .i-breadcrumb__link {
  gap: 0;
}

.i-breadcrumb.--homepage .i-breadcrumb__divider {
  display: none;
}

.i-contact-partner__header {
  min-height: 3.5rem;
}

.i-contact-partner__schedule-decor {
  pointer-events: none;
  height: .0625rem;
}

.i-contact-partner__image .img {
  max-width: 6rem;
  max-height: 3rem;
}

.i-contact-partner__location .link {
  align-items: flex-start;
}

.i-contact-partner__location .link .link__icon {
  margin-top: .25rem;
}

.i-contact-partner__hour-item {
  row-gap: .25rem;
  min-height: 2.5rem;
}

.i-contact-partner__hour-item:nth-child(odd) {
  background-color: #f4f5f7;
}

.i-contact-partner__hour-label {
  width: 100%;
  max-width: 5rem;
}

.i-contact-partner__mail, .i-contact-partner__web {
  word-break: break-all;
}

.i-contact-partner__phone .link__title, .i-contact-partner__mail .link__title, .i-contact-partner__web .link__title {
  font-weight: 600;
  line-height: 1.25rem;
}

.i-contact-partner {
  border: .0625rem solid #e8eaef;
}

.i-contact-partner.--map {
  border: none;
}

@media (width <= 47.999em) {
  .i-contact-partner.--map .i-contact-partner__hour-item {
    padding-inline: .5rem;
  }
}

.i-contact-partner__hours:not(:has(.i-contact-partner__hour-item)) {
  display: none;
}

.i-document {
  --background: transparent;
  background-color: var(--background);
  border: .0625rem solid #e8eaef;
  min-height: 4.5rem;
  transition: background-color .3s;
}

.i-document:hover {
  --background: #f4f5f7;
}

.i-document__title {
  text-overflow: ellipsis;
}

.i-document__button .btn {
  --svg-size: 1rem;
}

@media (width <= 47.999em) {
  .i-event {
    flex-direction: column;
    max-width: 20rem;
    margin-inline: auto;
  }
}

@media (width >= 48em) {
  .i-event__image-link {
    width: 17.5rem;
    height: 13.125rem;
  }
}

.i-faq__body {
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  background-color: #e7f0ff;
  grid-template-rows: 0fr;
  transition: grid-template-rows .5s;
  display: grid;
}

.i-faq__body-inner {
  position: relative;
  overflow: hidden;
}

.i-faq__body-inner:before {
  content: "";
  background-color: #7387ac;
  width: 100%;
  height: .0625rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.i-faq__header {
  cursor: pointer;
}

.i-faq {
  --border-radius: .375rem;
  border-radius: var(--border-radius);
  border: .0625rem solid #7387ac;
}

.i-faq svg {
  will-change: transform;
  transition: transform .3s;
}

.i-faq.--open .i-faq__toggler svg {
  transform: scale(-1);
}

.i-faq.--open .i-faq__body {
  grid-template-rows: 1fr;
}

.i-gallery-slide {
  --border-color: transparent;
}

.i-gallery-slide:before {
  content: "";
  pointer-events: none;
  z-index: 2;
  border: .0625rem solid var(--border-color);
  position: absolute;
  inset: 0;
}

.i-gallery-slide.--large {
  padding: 1rem;
}

.i-gallery-slide.--large:before {
  display: none;
}

.i-gallery-slide__image {
  display: block;
}

.i-gallery-slide__image button {
  background: none;
  border: none;
  outline: none;
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
}

.i-gallery-slide__image button .img {
  aspect-ratio: 1 / 1.25;
}

.i-gallery-slide__image a {
  outline: none;
  text-decoration: none;
  display: block;
}

.i-gallery-slide__tags {
  pointer-events: none;
  top: 1rem;
  left: 1rem;
}

.i-gallery-slide__grant {
  pointer-events: none;
  top: 1rem;
  right: 1rem;
  transform: translate(2.5625rem, -2.5rem);
}

.i-grant-widget {
  --arrow-position: 0;
}

.i-grant-widget:after {
  content: "";
  background-color: #e8eaef;
  width: 100%;
  height: .0625rem;
  position: absolute;
  bottom: -.5rem;
  left: 50%;
  transform: translate(-50%, 50%);
}

.i-grant-widget:last-child:after {
  display: none;
}

@media (hover: hover) {
  .i-grant-widget:hover {
    --arrow-position: -.5rem;
  }
}

.i-grant-widget__arrow {
  right: var(--arrow-position);
  bottom: var(--arrow-position);
  transition: right .3s, bottom .3s;
}

.i-grant-widget__grant {
  gap: .25rem;
  min-height: 2.25rem;
}

.i-grant-widget__link {
  inset: 0;
}

.i-hero__body {
  --max-width: 45rem;
  max-width: var(--max-width);
  order: 1;
}

@media (width <= 93.749em) {
  .i-hero__body {
    --max-width: 34rem;
  }
}

@media (width <= 74.999em) {
  .i-hero__body {
    --max-width: 30.5rem;
  }
}

@media (width <= 61.999em) {
  .i-hero__body {
    --max-width: 100%;
  }
}

@media (width >= 75em) {
  .i-hero {
    margin-bottom: 3.25rem;
  }
}

@media (width >= 36em) and (width <= 74.999em) {
  .i-hero .g-buttons {
    --spacing: 3rem;
  }
}

.i-hero__image {
  --width: 39rem;
  --y-translate: -3rem;
  --x-translate: 0;
  width: var(--width);
  transform: translate(var(--x-translate), var(--y-translate));
}

.i-hero__image > .img img {
  aspect-ratio: 1 / .794;
}

@media (width <= 93.749em) {
  .i-hero__image {
    --width: 34rem;
  }
}

@media (width <= 74.999em) {
  .i-hero__image {
    --width: 25.5rem;
    --y-translate: -2rem;
    --x-translate: 0;
  }
}

@media (width <= 61.999em) {
  .i-hero__image {
    margin-inline: auto;
    position: static;
    top: auto;
    right: auto;
    transform: translate3d(0, 0, 0);
  }
}

@media (width <= 35.999em) {
  .i-hero__image {
    --width: 100%;
  }
}

.i-hero__play {
  --play-width: 8rem;
  --bottom: 7.75rem;
  width: var(--play-width);
  left: 1rem;
  bottom: var(--bottom);
  transform: translateY(50%);
}

@media (width <= 93.749em) {
  .i-hero__play {
    --play-width: 6rem;
    --bottom: 5rem;
  }
}

@media (width <= 74.999em) {
  .i-hero__play {
    --bottom: 3rem;
  }
}

.i-hero__img-decoration {
  --decoration-width: 11rem;
  width: var(--decoration-width);
}

@media (width <= 93.749em) {
  .i-hero__img-decoration {
    --decoration-width: 8rem;
  }
}

@media (width <= 61.999em) {
  .i-hero__img-decoration {
    --decoration-width: 6rem;
  }
}

.i-hero__header.base-header {
  max-width: 100%;
}

@media (width <= 93.749em) {
  .i-hero__header.base-header .base-heading.u-h1 {
    --size: 2.5rem;
  }
}

@media (width <= 74.999em) {
  .i-hero__header.base-header .base-heading.u-h1 {
    --size: 2rem;
    --line-height: var(--size);
  }
}

.i-hero__link {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.i-hero__links {
  --spacing: 4rem;
  margin-top: var(--spacing);
}

@media (width <= 35.999em) {
  .i-hero__links {
    --spacing: 1.5rem;
    flex-direction: column;
    align-items: center;
  }
}

.i-hero-slide__body {
  --radius: .5rem;
  border-radius: var(--radius);
  backdrop-filter: blur(1.25rem);
  max-width: 35.5rem;
}

.i-hero-slide__body:before {
  content: "";
  z-index: -1;
  opacity: .4;
  border-radius: var(--radius);
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width <= 61.999em) {
  .i-hero-slide__body {
    gap: .5rem;
    max-width: 100%;
    position: static;
    left: auto;
    right: auto;
    transform: translate3d(0, 0, 0);
  }
}

@media (width <= 35.999em) {
  .i-hero-slide__body {
    padding: 1rem;
  }
}

.i-hero-slide {
  --offset: 4rem;
}

.i-hero-slide.--left .i-hero-slide__body {
  left: var(--offset);
}

.i-hero-slide.--right .i-hero-slide__body {
  right: var(--offset);
}

@media (width >= 62em) {
  .i-hero-slide.--center .i-hero-slide__body {
    left: 50%;
    transform: translateX(-50%)translateY(-50%);
  }
}

@media (width <= 61.999em) {
  .i-hero-slide {
    padding: 1rem;
  }

  .i-hero-slide__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.i-job__image {
  width: var(--image-size);
  height: var(--image-size);
  border-radius: 1rem;
  overflow: hidden;
}

.i-job__info {
  color: #2f4c4f;
  row-gap: .5rem;
}

@media (width >= 48em) {
  .i-job__info {
    flex-direction: row;
  }
}

.i-job {
  --image-size: 9rem;
  flex-direction: column;
}

@media (width >= 48em) {
  .i-job {
    flex-direction: row;
  }
}

.i-job.--card {
  --image-size: 28rem;
  flex-direction: column;
  width: 26rem;
}

.i-job.--card .i-job__image {
  max-width: 100%;
  height: auto;
}

.i-job.--card .i-job__info {
  flex-direction: column;
  gap: 1rem;
}

.i-job.--card .i-job__footer {
  flex-direction: column;
  align-items: flex-start;
}

.i-media.--video {
  cursor: pointer;
}

.i-media .img {
  aspect-ratio: 1 / .663;
}

.i-menu__submenu-button {
  --size: 1rem;
  height: var(--size);
  rotate: var(--submenu-button-rotate);
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  margin-left: auto;
  display: flex;
}

@media (width <= 61.999em) {
  .i-menu__submenu-button {
    --size: 3rem;
    will-change: scale;
    scale: var(--submenu-button-scale);
    transition: scale .3s;
    position: absolute;
    top: 0;
    right: 1.5rem;
  }
}

@media (width <= 61.999em) and (hover: hover) {
  .i-menu__submenu-button:hover svg {
    transform: translateY(.25rem);
  }
}

.i-menu__submenu-button svg {
  transition: transform .3s;
}

.i-menu {
  --submenu-display: none;
  --submenu-button-scale: 1;
  --submenu-button-rotate: 0deg;
  --padding-left: 1.5rem;
  --padding-right: 3rem;
  --background: transparent;
  --background-hover: #f4f5f7;
  --color: #302748;
}

.i-menu.--open {
  --submenu-display: block;
  --submenu-button-scale: -1;
}

.i-menu.--active {
  --color: #89001c;
}

.i-menu > .i-menu__submenu {
  display: var(--submenu-display);
}

.i-menu__link {
  padding-block: .5rem;
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
  background-color: var(--background);
  color: var(--color);
  will-change: background-color, color;
  align-items: center;
  gap: .5rem;
  min-height: 2.5rem;
  text-decoration: none;
  transition: background-color .3s, color .3s;
  display: flex;
  position: relative;
}

@media (hover: hover) {
  .i-menu__link:hover {
    --background: var(--background-hover);
  }
}

.i-menu.--level-2 {
  --padding-left: 1.5rem;
}

.i-menu.--level-3 {
  --padding-left: 2rem;
}

@media (width <= 61.999em) {
  .i-menu {
    --active-line: transparent;
  }

  .i-menu:before {
    content: "";
    background-color: var(--active-line);
    z-index: 1;
    width: .1875rem;
    height: 100%;
    transition: background-color .3s;
    position: absolute;
    top: 0;
    left: 0;
  }

  .i-menu.--active {
    --color: #302748;
    --active-line: #89001c;
  }

  .i-menu.--level-2 {
    --background: var(--background-hover);
  }

  .i-menu.--level-2.--active {
    --background: #fff;
  }

  .i-menu__link {
    min-height: 3rem;
  }
}

@media (width >= 62em) {
  .i-menu {
    --padding-left: 1rem;
    --padding-right: 1rem;
    --submenu-display: block;
    --opacity: 0;
    --translate-y: .5rem;
    --pointer-events: none;
  }

  .i-menu .i-menu__submenu {
    z-index: 50;
    opacity: var(--opacity);
    pointer-events: var(--pointer-events);
    transform: translateY(var(--translate-y));
    will-change: opacity, transform;
    background-color: #fff;
    width: 100%;
    min-width: 15rem;
    transition: opacity .3s, transform .3s;
    position: absolute;
    box-shadow: 0 .5rem 1rem .125rem #0f1c7b26;
  }

  .i-menu.--level-1 {
    position: relative;
  }

  .i-menu.--level-1 > .i-menu__submenu {
    top: 100%;
    left: 0;
  }

  .i-menu.--level-1:hover {
    --pointer-events: auto;
    --translate-y: 0;
    --opacity: 1;
  }

  .i-menu.--level-2 {
    --submenu-button-rotate: -90deg;
    position: relative;
  }

  .i-menu.--level-2 > .i-menu__submenu {
    top: 0;
    left: 100%;
  }

  .i-menu.--level-2 > .i-menu__submenu.--mirror {
    left: auto;
    right: 100%;
  }

  .i-menu.--level-2:hover {
    --pointer-events: auto;
    --translate-y: 0;
    --opacity: 1;
  }
}

@media (width >= 62em) and (width <= 93.749em) {
  .i-menu {
    --padding-left: .5rem;
    --padding-right: .5rem;
  }
}

.i-notification {
  --min-height: 3rem;
  min-height: var(--min-height);
}

@media (width <= 47.999em) {
  .i-notification {
    text-align: center;
  }
}

@media (width <= 35.999em) {
  .i-notification {
    font-size: .75rem;
  }
}

.i-notification__container {
  width: min(100% - 2rem, 88rem);
}

@media (width <= 47.999em) {
  .i-notification__container {
    flex-direction: column;
    gap: .5rem;
  }

  .i-notification__body {
    align-items: flex-start;
    gap: 0;
    padding-inline: 1.5rem;
  }

  .i-notification__close {
    position: absolute;
    right: .5rem;
  }
}

.i-parameter {
  min-height: 3rem;
}

.i-parameter:nth-child(2n) {
  background-color: #fff;
}

@media (width >= 62em) {
  .i-post.--hero {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 2rem;
    display: grid;
  }
}

@media (width <= 61.999em) {
  .i-post.--hero {
    max-width: 43rem;
    margin-inline: auto;
  }
}

.i-post.--hero .i-post__tags {
  margin-top: 0;
}

@media (width >= 62em) {
  .i-post.--hero .i-post__content-col {
    padding-block: 2rem;
  }
}

.i-post.--hero .i-post__content {
  justify-content: center;
}

@media (width >= 62em) {
  .i-post.--inline {
    grid-template-columns: minmax(10rem, 27.875rem) auto;
    gap: 2rem;
    display: grid;
  }

  .i-post.--inline .i-post__content {
    max-width: 70ch;
  }
}

@media (width <= 61.999em) {
  .i-post {
    max-width: 28rem;
    margin-inline: auto;
  }
}

.i-price-list {
  grid-template-columns: 1fr 1fr 9.5rem 1fr;
  grid-template-areas: "wattage price grant code";
  column-gap: .25rem;
}

.i-price-list:nth-child(2n) {
  background: none;
}

@media (width <= 47.999em) {
  .i-price-list {
    grid-template-columns: 1fr auto;
    grid-template-areas: "wattage grant"
                         "price grant"
                         "code grant";
  }
}

.i-price-list__wattage {
  grid-area: wattage;
}

.i-price-list__price {
  grid-area: price;
}

.i-price-list__code {
  grid-area: code;
  padding-left: 2.5rem;
}

@media (width <= 47.999em) {
  .i-price-list__code {
    padding-left: 0;
  }
}

.i-price-list__grant {
  min-height: 2rem;
  padding-block: .375rem;
  line-height: 1.25rem;
}

.i-price-list__grant-wrapper {
  grid-area: grant;
}

.i-priceplan {
  border: .0625rem solid #e7f0ff;
  border-radius: 1.5rem;
}

.i-priceplan.--highlight {
  border: none;
  box-shadow: 0 .5rem 1rem .125rem #0f1c7b26;
}

@media (width <= 61.999em) {
  .i-priceplan {
    max-width: 28rem;
    margin-inline: auto;
  }
}

.i-priceplan__ribbon {
  color: #f2f7f5;
  background: #e7f0ff;
  border-radius: .5rem;
  width: calc(100% - 4rem);
  top: -1rem;
}

.i-priceplan__price-before {
  text-decoration: line-through;
}

.i-priceplan__price {
  font-size: 4rem;
}

.i-product {
  --opacity: 0;
  --gradient-secondary: linear-gradient(256.08deg, #e8eaef 0%, #fff 59.93%);
  --background: #fff;
  --arrow-position: 0;
  background-color: var(--background);
  border: .0625rem solid #e8eaef;
  transition: background-color .3s;
}

.i-product:after {
  content: "";
  background: var(--gradient-secondary);
  opacity: var(--opacity);
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: opacity .3s;
  position: absolute;
  top: 0;
  left: 0;
}

@media (hover: hover) {
  .i-product:hover {
    --opacity: 1;
    --background: transparent;
    --arrow-position: .5rem;
  }
}

.i-product__tags {
  pointer-events: none;
  top: 1rem;
  left: 1rem;
}

.i-product__grant {
  pointer-events: none;
  top: 1rem;
  right: 1rem;
  transform: translate(2.5rem, -2.5rem);
}

.i-product__arrow {
  transform: translate(var(--arrow-position), var(--arrow-position));
  transition: transform .3s;
}

.i-product__link {
  inset: 0;
}

.i-product__price-block {
  mix-blend-mode: multiply;
}

.i-service {
  --size: 4rem;
  min-height: var(--size);
  min-width: var(--size);
  gap: .25rem;
  width: fit-content;
  padding-block: .625rem;
  padding-inline: .5rem;
}

.i-signpost__front-img {
  width: var(--front-width);
  aspect-ratio: var(--front-ratio);
}

.i-signpost__back-img {
  width: var(--back-width);
  height: var(--back-height);
  aspect-ratio: var(--back-ratio);
}

.i-signpost__back-img:before {
  content: "";
  background: var(--gradient-primary);
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width <= 61.999em) {
  .i-signpost__back-img:after {
    content: "";
    z-index: 2;
    background-image: url("union.6bf9e93a.svg");
    background-repeat: no-repeat;
    background-size: 3.5rem;
    width: 3.5rem;
    height: 3.5rem;
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
  }
}

@media (width <= 35.999em) {
  .i-signpost__back-img:after {
    background-size: 2rem;
    width: 2rem;
    height: 2rem;
    right: 1rem;
  }
}

.i-signpost__heading {
  --font-size: 2rem;
  font-weight: 700;
}

@media (width <= 61.999em) {
  .i-signpost__heading {
    font-size: var(--font-size);
    line-height: var(--font-size);
  }
}

@media (width <= 47.999em) {
  .i-signpost__heading {
    --font-size: 1.5rem;
  }
}

@media (width <= 35.999em) {
  .i-signpost__heading {
    --font-size: 1.125rem;
    line-height: 1.5rem;
  }
}

.i-signpost {
  --gradient-primary: linear-gradient(90deg, #f2f7f500 0%, #f2f7f5 100%);
  --gradient-secondary: linear-gradient(256.08deg, #e8eaef 0%, #fff 59.93%);
  --min-height: 12.1875rem;
  --padding-left: calc(8.5625rem + 1.5rem);
  --padding-right: 3.5rem;
  --arrow-position: 1.5rem;
  --opacity: 0;
  --front-width: 8.5625rem;
  --back-width: 100%;
  --back-height: 5.1875rem;
  --front-ratio: 1 / 1.423;
  --back-ratio: 1 / .121;
  min-height: var(--min-height);
}

@media (width <= 35.999em) {
  .i-signpost {
    --arrow-position: 1rem;
  }
}

.i-signpost:before {
  content: "";
  z-index: 3;
  pointer-events: none;
  border: .0625rem solid #e8eaef;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

@media (hover: hover) {
  .i-signpost:hover {
    --opacity: 1;
    --arrow-position: 1rem;
  }
}

@media (width >= 62em) and (width <= 74.999em) {
  .i-signpost.--default {
    --padding-left: calc(5.625rem + 1.5rem);
    --front-width: 5.625rem;
    --front-ratio: 1 / 2.17;
  }
}

@media (width <= 35.999em) {
  .i-signpost.--default {
    --min-height: 7.0625rem;
    --front-width: 5rem;
    --front-ratio: 1 / 1.413;
    --back-height: 3rem;
    --padding-left: calc(5rem + 1rem);
  }
}

.i-signpost.--big {
  --min-height: 25.875rem;
  --padding-left: calc(18.4375rem + 1.5rem);
  --front-width: 18.4375rem;
  --front-ratio: 1 / 1.403;
  --back-ratio: 1 / .244;
  --back-height: 10.5rem;
  grid-row: span 2;
}

@media (width >= 75em) and (width <= 93.749em) {
  .i-signpost.--big {
    --padding-left: calc(12.5rem + 1.5rem);
    --front-width: 12.5rem;
    --front-ratio: 1 / 2.07;
  }
}

@media (width <= 74.999em) {
  .i-signpost.--big {
    grid-column: span 2;
  }

  .i-signpost.--big:nth-child(2) {
    order: -1;
  }
}

@media (width <= 61.999em) {
  .i-signpost.--big {
    --min-height: 12.1875rem;
    --back-height: 5.1875rem;
    --padding-left: calc(8.5625rem + 1.5rem);
    --front-width: 8.5625rem;
    --front-ratio: 1 / 1.423;
  }
}

@media (width <= 35.999em) {
  .i-signpost.--big {
    --min-height: 7.0625rem;
    --front-width: 5rem;
    --front-ratio: 1 / 1.413;
    --back-height: 3rem;
    --padding-left: calc(5rem + 1rem);
  }
}

.i-signpost__link {
  inset: 0;
}

.i-signpost__body {
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
}

.i-signpost__body:after {
  content: "";
  background: var(--gradient-secondary);
  opacity: var(--opacity);
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: opacity .3s;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width <= 35.999em) {
  .i-signpost__body {
    padding-block: .875rem;
  }
}

.i-signpost__arrow {
  right: var(--arrow-position);
  bottom: var(--arrow-position);
  transition: right .3s, bottom .3s;
}

.i-signpost__overline {
  width: 2.5rem;
  height: .1875rem;
}

.i-signpost__decoration {
  right: 1.5rem;
}

.i-step {
  --size: 9rem;
}

.i-step:before {
  content: "";
  background-color: #e7f0ff;
  display: block;
  position: absolute;
}

@media (width <= 35.999em) {
  .i-step:before {
    display: none;
  }
}

.i-step.--column {
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.i-step.--column:before {
  left: 50%;
  top: calc(var(--size) / 2);
  width: calc(100% + 2rem);
  height: .25rem;
  transform: translateX(-50%);
}

.i-step.--inline:before {
  top: 0;
  left: calc(var(--size) / 2);
  width: .25rem;
  height: calc(100% + 4.5rem);
}

@media (width <= 35.999em) {
  .i-step.--inline {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
}

.i-step__icon {
  width: var(--size);
  height: var(--size);
  background-color: #e7f0ff;
  border-radius: 50%;
}

.i-step__count {
  color: #2f4c4f;
  font-size: 3.5rem;
  bottom: 0;
  left: 0;
}

.i-step__count:after {
  content: ".";
}

.i-tag {
  --padding-x: 1rem;
  --padding-y: .25rem;
  --min-height: 2rem;
  --font-size: 1rem;
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  min-height: var(--min-height);
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.i-tag.--sm {
  --padding-x: .5rem;
  --padding-y: .125rem;
  --min-height: 1.5rem;
  --font-size: .875rem;
  --line-height: 1rem;
}

.i-tag.--lg {
  --padding-x: 1.5rem;
  --padding-y: .25rem;
  --min-height: 2.5rem;
}

.i-tag {
  --font-color: #302748;
  --background-color: #ffb489;
  background-color: var(--background-color);
  color: var(--font-color);
  border: .0625rem solid var(--background-color);
  border-radius: 0;
  width: fit-content;
}

.i-tag.--secondary {
  --background-color: #bacdff;
}

.i-tag.--outline {
  border-color: #7387ac;
}

.i-tag.--blank {
  --background-color: transparent;
  min-height: auto;
  padding: 0;
}

.i-tag.--icon-before .i-tag__icon {
  order: -1;
}

.i-tag.--icon-only {
  width: var(--min-height);
  height: var(--min-height);
  min-height: auto;
  padding: 0;
}

.i-tag.--icon-only .i-tag__title {
  display: none;
}

a.i-tag, .i-tag.--hoverable {
  cursor: pointer;
  text-decoration: none;
  transition: border-color .3s;
}

a.i-tag:hover, .i-tag.--hoverable:hover {
  border-color: #2f4c4f;
}

a.i-tag.--blank {
  text-decoration: underline #0000;
  transition: text-decoration-color .3s;
}

a.i-tag.--blank:hover {
  -webkit-text-decoration-color: var(--font-color);
  text-decoration-color: var(--font-color);
  border-color: #0000;
}

.i-usp__title {
  padding-inline: var(--padding-x);
}

@media (width <= 93.749em) {
  .i-usp__title {
    --padding-x: 2rem;
  }
}

@media (width <= 74.999em) {
  .i-usp__title {
    --padding-x: 1rem;
  }
}

@media (width <= 35.999em) {
  .i-usp__title {
    --padding-x: .75rem;
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

.i-usp {
  --padding-x: 3.5rem;
}

.i-usp:after {
  content: "";
  background-color: #e8eaef;
  width: .0625rem;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

@media (width <= 35.999em) {
  .i-usp:after {
    right: -.25rem;
    transform: translateX(50%);
  }
}

.i-usp.--product {
  --padding-x: 0;
  text-align: left;
  align-items: flex-start;
}

.i-usp.--product .i-usp__title {
  text-align: left;
  padding-inline: 0;
}

.i-usp__icon {
  min-width: 6rem;
  min-height: 3.5rem;
}

.i-usp__icon.--number {
  gap: .25rem;
}

@media (width <= 35.999em) {
  .i-usp__icon.--number {
    gap: .125rem;
  }

  .i-usp__icon.--number .icon {
    --size: 1.125rem;
  }

  .i-usp__icon {
    min-width: 4.25rem;
    min-height: 2.5rem;
  }

  .i-usp__icon .icon {
    --size: 2.5rem;
  }
}

.i-usp__number {
  font-size: 3.5rem;
}

@media (width <= 35.999em) {
  .i-usp__number {
    font-size: 2rem;
  }
}

.i-usp__count {
  font-size: 3rem;
  line-height: 3rem;
}

.i-usp__square {
  background-color: #2f4c4f;
  width: .5rem;
  height: .5rem;
}

.c-language-select {
  --dropdown-opacity: 0;
  --dropdown-pointer-events: none;
}

.c-language-select:hover {
  --dropdown-opacity: 1;
  --dropdown-pointer-events: auto;
}

.c-language-select__drowpdown {
  opacity: var(--dropdown-opacity);
  pointer-events: var(--dropdown-pointer-events);
  background-color: #fff;
  transition: opacity .3s;
  top: 100%;
  left: 0;
  box-shadow: 0 .5rem 1rem .125rem #0f1c7b26;
}

.c-language-select__active {
  min-height: 2.5rem;
}

.c-language-select__dropdown-link {
  text-decoration: none;
  transition: background-color .3s;
}

.c-language-select__dropdown-link:hover {
  background-color: #e8eaef;
}

.link.--animation-right svg {
  transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
}

.link.--animation-right:hover svg {
  transform: translateX(.5rem);
}

.link.--animation-zoom:hover svg {
  transform: scale(1.1);
}

.link.--black {
  color: #081828;
  text-decoration-color: #0000;
}

.link.--black:hover, .link.--black:focus {
  color: #081828;
  text-decoration-color: #081828;
}

.link.--white {
  color: #fff;
  text-decoration-color: #0000;
}

.link.--white:hover, .link.--white:focus {
  color: #fff;
  text-decoration-color: #fff;
}

.link.--primary {
  color: #302748;
  text-decoration-color: #0000;
}

.link.--primary:hover, .link.--primary:focus {
  color: #89001c;
  text-decoration-color: #89001c;
}

.link.--primary:hover .icon.--primary, .link.--primary:focus .icon.--primary {
  --color: #89001c;
}

.link.--conversion {
  color: #89001c;
  text-decoration-color: #0000;
}

.link.--conversion:hover, .link.--conversion:focus {
  color: #89001c;
  text-decoration-color: #89001c;
}

.link__icon svg {
  will-change: transform;
  transition: transform .3s;
}

.link {
  font-weight: 500;
  text-decoration: underline #0000;
  transition: text-decoration-color .3s, color .3s;
}

.link:hover {
  -webkit-text-decoration-color: inherit;
  text-decoration-color: inherit;
}

.link.--icon-before .link__icon {
  order: -1;
}

.link.--underline-initial {
  -webkit-text-decoration-color: inherit;
  text-decoration-color: inherit;
}

.link.--underline-initial:hover, .link.--underline-initial:focus {
  text-decoration-color: #0000;
}

.link.--no-underline {
  text-decoration: none;
}

.main-footer-column__content.entry-content p:last-child {
  margin-bottom: 0;
}

.main-footer-column__logo {
  max-width: 8.875rem;
  max-height: 7.5rem;
}

.main-footer__mail {
  word-break: break-all;
}

.main-footer__phone .link__title, .main-footer__mail .link__title {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.main-footer__location-link .link {
  align-items: flex-start;
}

.main-footer__location-link .link .link__icon {
  padding-block: .25rem;
}

.main-footer-column__link {
  text-decoration-color: #0000;
}

.main-footer-column__link:hover, .main-footer-column__link:focus {
  text-decoration-color: #302748;
}

.main-footer {
  --padding-top: 3.5rem;
  padding-top: var(--padding-top);
}

@media (width <= 35.999em) {
  .main-footer {
    --padding-top: 1.5rem;
  }
}

.main-footer__top {
  --padding-y: 4rem;
  padding-block: var(--padding-y);
}

@media (width <= 35.999em) {
  .main-footer__top {
    --padding-y: 2rem;
  }
}

@media (width >= 36em) {
  .main-footer__columns {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

@media (width >= 75em) {
  .main-footer__columns {
    grid-template-columns: repeat(3, 1fr) 15rem;
    gap: 3.875rem;
  }
}

.main-footer__strip {
  --height: .5rem;
  --max-width: 100%;
  max-width: var(--max-width);
  height: var(--height);
  width: 100%;
}

.main-footer__strip.--upper {
  --height: .0625rem;
  --max-width: calc(100% - 3.25rem);
  transform: translate(-50%, -2rem);
}

@media (width >= 36em) {
  .main-footer__strip.--upper {
    transform: translate(-50%, -4rem);
  }
}

@media (width >= 62em) and (width <= 74.999em) {
  .m-header-logo {
    max-width: 7.875rem;
  }
}

@media (width <= 61.999em) {
  .m-header-logo {
    max-width: 8.875rem;
  }
}

.m-header-search {
  --translate-y: -150%;
  --pointer-events: none;
  --opacity: 0;
}

.m-header-search.--open {
  --translate-y: 0;
  --pointer-events: auto;
  --opacity: 1;
}

.m-header-search__form {
  z-index: 2;
  transform: translateY(var(--translate-y));
  pointer-events: var(--pointer-events);
  opacity: var(--opacity);
  will-change: transform, opacity;
  background-color: #fff;
  width: 100%;
  height: 100%;
  transition: opacity .3s, transform .3s;
  position: absolute;
  top: 0;
  left: 0;
}

.m-header {
  --min-height: 6.5rem;
  --padding-y: 1rem;
  z-index: 10;
  background-color: #fff;
  transition: transform .3s;
  position: sticky;
  top: 0;
  left: 0;
}

.m-header__body {
  min-height: var(--min-height);
  padding-block: var(--padding-y);
}

@media (width <= 74.999em) {
  .m-header__body {
    --min-height: 5rem;
    --padding-y: .5rem;
  }
}

@media (width >= 62em) and (width <= 93.749em) {
  .m-header__side-menu {
    gap: .5rem;
  }
}

.m-header__strip {
  background: #2f4c4f;
  width: 100%;
  max-width: 100%;
  height: .5rem;
}

.m-header__dealer-btn.btn {
  min-width: auto;
}

.m-nav {
  --transition-timing: 0;
}

@media (width >= 62em) {
  .m-nav {
    --margin-right: 2rem;
    margin-left: auto;
    margin-right: var(--margin-right);
  }

  .m-nav:after {
    content: "";
    top: 50%;
    right: calc(var(--margin-right) / -2);
    background-color: #d9dbe1;
    width: .0625rem;
    height: 1.5rem;
    position: absolute;
    transform: translate(-50%, -50%);
  }
}

@media (width >= 62em) and (width >= 62em) and (width <= 74.999em) {
  .m-nav:after {
    right: -1rem;
  }
}

@media (width >= 93.75em) {
  .m-nav {
    --margin-right: 4rem;
  }
}

@media (width <= 61.999em) {
  .m-nav {
    transition: transform var(--transition-timing) ease;
    z-index: 100;
    background-color: #fff;
    width: 100%;
    max-width: 26.25rem;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    overflow: hidden auto;
    transform: translateX(100%);
    box-shadow: 0 .5rem 1rem .125rem #0f1c7b26;
  }

  .m-nav.--open {
    transform: translateX(0);
  }

  .m-nav.--transition {
    --transition-timing: .3s;
  }
}

@media (width >= 62em) and (width <= 74.999em) {
  .m-nav {
    margin-right: 2rem;
  }
}

@media (width >= 62em) {
  .m-nav__list {
    flex-direction: row;
    align-items: center;
  }
}

@media (width >= 62em) and (width <= 93.749em) {
  .m-nav__list {
    gap: .5rem;
  }
}

.m-nav__strip {
  background: #d9dbe1;
  width: 100%;
  max-width: 100%;
  height: .5rem;
}

.m-nav__header:after {
  content: "";
  pointer-events: none;
  background-color: #d9dbe1;
  width: 100%;
  max-width: 100%;
  height: .0625rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.m-nav__language-link:hover, .m-nav__language-link:focus {
  color: #9893a4;
}

.c-modal__dialog {
  pointer-events: none;
  cursor: cell;
  width: auto;
  margin: 2rem auto;
  position: relative;
}

.c-modal__content {
  width: var(--width);
  pointer-events: auto;
  cursor: auto;
  transition: transform var(--transtion-length) ease;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  margin-inline: auto;
  position: relative;
  transform: translateY(2rem);
  box-shadow: .25rem .25rem 1.5rem #0f26391a;
}

.c-modal__body {
  flex: auto;
  padding: 2rem 1.5rem;
  position: relative;
}

.c-modal__header {
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 5rem 0 1.5rem;
  display: flex;
}

.c-modal__button-container {
  order: -1;
  justify-content: flex-end;
  display: flex;
  right: .5rem;
}

.c-modal__close-cross {
  transform: translate(-1rem, 1rem);
}

.c-modal__footer {
  padding: 0 1.5rem 2rem;
}

.c-modal__footer.--right {
  justify-content: flex-end;
}

.c-modal__footer.--center {
  justify-content: center;
}

.c-modal__footer.--between {
  justify-content: space-between;
}

.c-modal__heading {
  font-size: 1.375rem;
}

.c-modal {
  --transtion-length: .3s;
  --width: min(100% - 2rem, 45rem);
  z-index: 9000;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  transition: opacity var(--transtion-length) ease;
  background-color: #00000040;
  outline: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden auto;
}

.c-modal.--open {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

.c-modal.--open .c-modal__content {
  transform: translateY(0);
}

.c-modal.--hidden {
  visibility: hidden;
}

.c-modal.--size-sm {
  --width: min(100% - 2rem, 30rem);
}

.c-modal.--size-xl {
  --width: min(100% - 2rem, 71.25rem);
}

.c-overlay {
  --opacity: 0;
  --pointer-events: none;
  --position: fixed;
  z-index: 4;
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  position: var(--position);
  background-color: #081828;
  transition: opacity .3s;
  inset: 0;
}

.c-overlay.--show {
  --opacity: .75;
  --pointer-events: auto;
}

.c-overlay.--absolute {
  --position: absolute;
  width: 100%;
  height: 100%;
}

.link.c-pagination__link {
  --background: transparent;
  --size: 2.5rem;
  background-color: var(--background);
  min-height: var(--size);
  border-radius: 0;
  padding: .5rem 1rem;
  transition-property: text-decoration-color, color, background-color;
}

.link.c-pagination__link:hover {
  --background: #f2f7f5;
}

.link.c-pagination__link.--page-number {
  min-width: var(--size);
  padding: .5rem;
  text-decoration: none;
}

.link.c-pagination__link.--active {
  --background: #2f4c4f;
  pointer-events: none;
  color: #fff;
}

.link.c-pagination__link.--blank {
  pointer-events: none;
}

@media (width <= 47.999em) {
  .c-pagination__items {
    flex-basis: 100%;
    order: -1;
    justify-content: center;
  }

  .c-pagination {
    justify-content: center;
  }
}

.partner-map {
  border: .0625rem solid #e8eaef;
  margin-top: 6.5rem;
  padding-top: 4rem;
}

@media (width <= 47.999em) {
  .partner-map {
    border: none;
    padding: 2.5rem 0 0;
  }
}

.partner-map__form {
  width: 100%;
  max-width: 50.0625rem;
}

@media (width <= 93.749em) {
  .partner-map__form {
    transform: translate(-50%, calc(-50% - 1.125rem));
  }
}

@media (width <= 61.999em) {
  .partner-map__form {
    max-width: 35.5rem;
    padding-inline: 0;
  }
}

@media (width <= 35.999em) {
  .partner-map__form {
    transform: translate(-50%, calc(-50% - 1.25rem));
  }
}

iframe + div {
  border: none !important;
}

#map {
  width: 100%;
  height: 36.8125rem;
  overflow: hidden;
}

@media (width <= 61.999em) {
  #map {
    height: 32rem;
  }
}

#map .gm-style-mtc > button {
  min-height: 3rem !important;
}

#map .gm-fullscreen-control {
  min-width: 3rem !important;
  min-height: 3rem !important;
}

#map .gm-style-iw {
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  display: flex;
  padding: 1rem !important;
  font-size: 1rem !important;
}

@media (width <= 47.999em) {
  #map .gm-style-iw {
    min-height: 20rem !important;
    padding: 1rem .75rem !important;
  }
}

#map .gm-style-iw-chr, #map .gm-style-iw-tc {
  display: none !important;
}

#map .gm-style-iw-c {
  overflow-y: auto;
  border-radius: 0 !important;
  box-shadow: 0 .5rem 1.5rem #30274829 !important;
}

#map .gm-style-iw > div {
  overflow: visible !important;
}

#map .gm-style-iw button {
  display: none !important;
}

#map .gm-style {
  font-family: Archivo, sans-serif;
}

#map .bounce {
  animation: 2s bounce;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-1.875rem);
  }

  60% {
    transform: translateY(-.9375rem);
  }
}

.controls .f-text__wrap {
  border: none;
}

.pac-container {
  display: block;
}

.c-scroll-up {
  --size: 2.5rem;
  --offset: 2.5rem;
  --radius: 0;
  --border-color: #e8eaef;
  --background: #fff;
  --background-hover: #e8eaef;
  --icon-size: 1rem;
  --icon-color: #89001c;
  width: var(--size);
  height: var(--size);
  bottom: var(--offset);
  right: var(--offset);
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  background-color: var(--background);
  z-index: 50;
  will-change: opacity, transform, background-color;
  pointer-events: none;
  opacity: 0;
  transform: translateY(var(--offset)) scale(.8);
  cursor: pointer;
  transition: background-color .3s, opacity .3s cubic-bezier(.68, -.55, .27, 1.55), transform .3s cubic-bezier(.68, -.55, .27, 1.55);
}

.c-scroll-up:hover {
  --background: var(--background-hover);
}

.c-scroll-up:after {
  content: "";
  opacity: 0;
  border-radius: 0;
  width: 100%;
  height: 100%;
  transition: opacity .5s, box-shadow .5s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 .625rem 2.5rem #fff;
}

.c-scroll-up:active:after {
  opacity: 1;
  transition: all;
  position: absolute;
  box-shadow: 0 0 #fff;
}

.c-scroll-up svg {
  width: var(--icon-size);
  height: var(--icon-size);
  transform: rotate(180deg);
}

.c-scroll-up path {
  fill: var(--icon-color);
}

.c-scroll-up.--active {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0)scale(1);
}

.s-breadcrumbs__homepage {
  display: block;
}

.s-calendar__app {
  --fc-border-color: #ebebeb;
  --fc-today-bg-color: #e7f0ff;
  --fc-event-bg-color: #2f4c4f;
  --fc-button-bg-color: #2f4c4f;
  --fc-button-disabled-bg-color: #d9dbe1;
  --fc-button-disabled-border-color: #d9dbe1;
  --fc-button-border-color: #2f4c4f;
  --fc-button-active-bg-color: #89001c;
  --fc-button-active-border-color: #89001c;
  --fc-button-hover-bg-color: #89001c;
  --fc-button-hover-border-color: #89001c;
  height: 35.625rem;
}

.s-calendar__app.fc {
  height: auto;
}

.s-calendar__app.fc .fc-button {
  border-radius: 0;
}

.s-calendar__app.fc .fc-button-primary:disabled {
  background-color: var(--fc-button-disabled-bg-color);
  border-color: var(--fc-button-disabled-border-color);
  opacity: 1;
}

.s-calendar__app.--close {
  display: none;
}

.s-calendar__app .fc-daygrid {
  background-color: #fff;
}

.s-calendar__app .fc-event {
  border: none;
  border-radius: 0;
}

.s-calendar__app .fc-daygrid-day-number {
  color: #2f4c4f;
  text-decoration: none;
}

.s-calendar__app .fc-event-time {
  flex-shrink: 0;
}

.s-calendar__app .fc-col-header-cell-cushion {
  text-decoration: none;
}

.s-calendar__app .fc-col-header-cell {
  padding: .5rem;
}

.s-calendar__app .fc-toolbar-chunk {
  flex-wrap: wrap;
  justify-content: flex-end;
  row-gap: .25rem;
  display: flex;
}

.s-calendar__toggler.--close svg {
  transform: scale(-1);
}

@media (width >= 75em) {
  .s-contact-overview__grid {
    grid-template-columns: repeat(12, 1fr);
    display: grid;
  }
}

.s-contact-overview__operator {
  border: .0625rem solid #e8eaef;
  grid-column: 1 / span 8;
}

@media (width <= 47.999em) {
  .s-contact-overview__operator {
    flex-direction: column;
    align-items: flex-start;
  }
}

.s-contact-overview__manufacturer {
  grid-column: span 4;
}

.s-contact-overview__img {
  width: 21.75rem;
}

.s-contact-overview__img .img {
  aspect-ratio: 1 / 1.023;
}

@media (width <= 47.999em) {
  .s-contact-overview__img {
    width: 100%;
    max-width: 21.75rem;
    margin-inline: auto;
  }
}

.s-contact-overview__operator-location .link, .s-contact-overview__manufacturer-location .link {
  align-items: flex-start;
}

.s-contact-overview__operator-location .link .link__icon, .s-contact-overview__manufacturer-location .link .link__icon {
  margin-top: .25rem;
}

.s-contact-overview__mail {
  word-break: break-all;
}

.s-contact-overview__phone .link__title, .s-contact-overview__mail .link__title {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.s-contact-overview__manufacturer-decoration {
  pointer-events: none;
  transform: translate(2rem);
}

.s-contact-overview__manufacturer-decoration img {
  height: 16rem;
}

.s-contact-partner .base-heading {
  max-width: 28.125rem;
}

@media (width <= 74.999em) {
  .s-documents {
    order: -1;
  }
}

.s-error-404__heading {
  --font-size: 11rem;
  font-size: var(--font-size);
}

@media (width <= 47.999em) {
  .s-error-404__heading {
    --font-size: 8.5rem;
  }
}

.s-error-404__sub-heading {
  margin-inline: auto;
  max-width: 45rem;
  margin-bottom: 1.5rem;
}

.s-error-404__perex {
  text-align: center;
  margin-inline: auto;
  max-width: 45rem;
  margin-bottom: 0;
}

.s-hero {
  --gradient: linear-gradient(256.08deg, #e8eaef 0%, #fff 59.93%);
}

@media (width >= 62em) {
  .s-hero {
    background: var(--gradient);
  }
}

@media (width <= 61.999em) {
  .s-hero {
    padding-top: 1rem;
  }

  .s-hero:before {
    content: "";
    background: var(--gradient);
    z-index: -1;
    width: 100%;
    max-width: 100%;
    height: 20.5rem;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.s-jobs__footer {
  background-color: #e7f0ff;
}

@media (width >= 48em) {
  .s-jobs__footer {
    padding: 3rem 4rem;
  }
}

.s-partner-list__empty.--hidden, .s-partner-list__load-more-wrap.--hidden {
  display: none;
}

.s-partner-list__form {
  width: 100%;
  max-width: 38.0625rem;
}

@media (width <= 35.999em) {
  .s-post-detail__meta {
    flex-direction: column;
    align-items: start;
  }

  .s-post-detail__share-links {
    flex-direction: column;
    align-items: normal;
    margin-left: 0;
  }

  .s-post-detail__share {
    flex-direction: column;
  }
}

.s-price-list {
  margin-bottom: 4rem;
}

@media (width <= 35.999em) {
  .s-price-list {
    margin-bottom: 2rem;
  }
}

.s-price-list__table {
  border: .0625rem solid #ecf1f5;
}

.s-price-list__header {
  grid-template-columns: 1fr 1fr 9.5rem 1fr;
  grid-template-areas: "wattage price grant code";
  column-gap: .25rem;
}

@media (width <= 47.999em) {
  .s-price-list__header {
    grid-template-columns: 1fr auto;
    grid-template-areas: "wattage grant"
                         "price grant"
                         "code grant";
  }
}

.s-price-list__wattage {
  grid-area: wattage;
  width: 100%;
  max-width: 11.25rem;
}

@media (width >= 75em) and (width <= 93.749em) {
  .s-price-list__wattage {
    max-width: 7.8125rem;
  }
}

@media (width >= 48em) and (width <= 61.999em) {
  .s-price-list__wattage {
    max-width: 7.1875rem;
  }
}

@media (width <= 47.999em) {
  .s-price-list__wattage {
    width: auto;
    max-width: 100%;
  }
}

.s-price-list__price {
  grid-area: price;
}

.s-price-list__code {
  grid-area: code;
  width: 100%;
  max-width: 11.125rem;
  padding-left: 2.5rem;
}

@media (width >= 75em) and (width <= 93.749em) {
  .s-price-list__code {
    max-width: 8.9375rem;
  }
}

@media (width >= 62em) and (width <= 74.999em) {
  .s-price-list__code {
    max-width: 10.625rem;
  }
}

@media (width >= 48em) and (width <= 61.999em) {
  .s-price-list__code {
    max-width: 7.1875rem;
  }
}

@media (width <= 47.999em) {
  .s-price-list__code {
    width: auto;
    max-width: 100%;
    padding-left: 0;
  }
}

.s-price-list__grant {
  grid-area: grant;
  width: 100%;
  max-width: 9.5rem;
}

@media (width >= 75em) and (width <= 93.749em) {
  .s-price-list__grant {
    max-width: 8.4375rem;
  }
}

@media (width <= 47.999em) {
  .s-price-list__grant {
    width: auto;
    max-width: 100%;
    margin-left: auto;
  }
}

.s-product-intro__grid {
  grid-template-columns: 1fr 39rem;
  grid-template-areas: "gallery content";
  gap: 6rem;
}

@media (width <= 93.749em) {
  .s-product-intro__grid {
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
  }
}

@media (width <= 61.999em) {
  .s-product-intro__grid {
    grid-template-columns: 1fr;
    grid-template-areas: "gallery"
                         "content";
  }
}

@media (width <= 35.999em) {
  .s-product-intro__grid {
    gap: 2rem;
  }
}

.s-product-intro__gallery-slider {
  grid-area: gallery;
}

.s-product-intro__content {
  grid-area: content;
}

.s-product-intro__param-items {
  border-bottom: .0625rem solid #e8eaef;
}

.s-product-intro__param-decor {
  pointer-events: none;
  height: .0625rem;
}

.s-product-intro__price {
  background: linear-gradient(90deg, #ffffff1a 0%, #f2f7f5 100%);
}

@media (width <= 93.749em) {
  .s-product-intro__buttons.g-base {
    gap: 1rem;
  }
}

@media (width >= 48em) and (width <= 61.999em) {
  .s-product-intro__buttons.g-base {
    gap: 0;
  }
}

.s-product-intro__buttons.g-base .btn {
  width: 50%;
}

@media (width <= 93.749em) {
  .s-product-intro__buttons.g-base .btn {
    width: 100%;
  }
}

@media (width >= 48em) and (width <= 61.999em) {
  .s-product-intro__buttons.g-base .btn {
    width: 50%;
  }
}

.s-product-list__subheader {
  margin-top: 4rem;
}

@media (width <= 35.999em) {
  .s-product-list__subheader {
    max-width: 20.5rem;
    margin-inline: auto;
  }
}

@media (width <= 392px) {
  .s-product-list__subheader {
    max-width: 100%;
  }
}

.s-product-slider {
  overflow: hidden;
}

@media (width <= 47.999em) {
  .s-product-slider__slider {
    width: min(100%, 20.5rem);
    margin-inline: auto;
  }
}

@media (width >= 62em) {
  .s-text-media__grid {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.s-text-media__col.--content-col {
  order: var(--content-order);
  padding-left: var(--content-offset-left);
  padding-right: var(--content-offset-right);
}

.s-text-media {
  --content-offset-left: 1rem;
  --content-offset-right: 0;
  --content-order: 1;
}

@media (width <= 61.999em) {
  .s-text-media {
    --content-offset-left: 0;
  }
}

@media (width >= 62em) {
  .s-text-media.--reverse {
    --content-offset-left: 0;
    --content-offset-right: 1rem;
    --content-order: -1;
  }
}

.s-usps.--product {
  margin-bottom: 4rem;
}

@media (width <= 35.999em) {
  .s-usps.--product {
    margin-bottom: 2rem;
  }
}

.w-grant {
  border: .5rem solid #2f4c4f;
  width: min(100%, 28rem);
}

.widget_categories select {
  --border-color: #a9a9b2;
  --radius: .25rem;
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  width: 100%;
  height: 3rem;
  margin: 0;
  padding: .5rem;
}

.wp-aside-widgets .cat-item {
  align-items: center;
  padding-right: .5rem;
  display: flex;
}

.wp-aside-widgets .cat-item > a {
  width: 100%;
}

.widget_media_image a {
  display: block;
}

.widget_nav_menu .menu-item-has-children {
  --submenu-display: none;
  --svg-rotate: 0deg;
  position: relative;
}

.widget_nav_menu .menu-item-has-children .sub-menu {
  padding-left: 1rem;
}

.widget_nav_menu .menu-item-has-children > .sub-menu {
  display: var(--submenu-display);
}

.widget_nav_menu .menu-item-has-children > a {
  padding-right: 3rem;
}

.widget_nav_menu .menu-item-has-children.--open {
  --submenu-display: block;
  --svg-rotate: 180deg;
}

.widget_pages .page_item.active {
  text-decoration: underline;
}

.widget_pages .page_item_has_children {
  --submenu-display: none;
  --svg-rotate: 0deg;
  position: relative;
}

.widget_pages .page_item_has_children .sub-menu {
  padding-left: 1rem;
}

.widget_pages .page_item_has_children > .children {
  display: var(--submenu-display);
}

.widget_pages .page_item_has_children > a {
  padding-right: 3rem;
}

.widget_pages .page_item_has_children.--open {
  --submenu-display: block;
  --svg-rotate: 180deg;
}

.widget_search form > div {
  gap: .5rem;
  display: flex;
}

.widget_search input[type="text"] {
  --border-color: #a9a9b2;
  --radius: .25rem;
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  appearance: none;
  background-clip: padding-box;
  width: 100%;
  min-height: 2.875rem;
  padding-left: .5rem;
  display: block;
}

.widget_search input[type="text"]:focus {
  border-color: none;
  outline: none;
}

.widget_search input[type="submit"] {
  --border-radius: .375rem;
  --background: #2f4c4f;
  --font-color: #fff;
  color: var(--font-color);
  background-color: var(--background);
  border-radius: var(--border-radius);
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  touch-action: manipulation;
  border: none;
  height: 100%;
  min-height: 2.875rem;
  padding-block: .5rem;
  padding-inline: 1rem;
  text-decoration: none;
  transition: background-color .3s;
  display: block;
}

.widget_search input[type="submit"]:hover {
  color: var(--font-color);
  text-decoration: none;
}

.widget_search input[type="submit"]:focus, .widget_search input[type="submit"]:active {
  color: var(--font-color);
  outline: 0;
  text-decoration: none;
}

@media (hover: hover) {
  .widget_search input[type="submit"]:hover {
    --background: #f2f7f5;
  }
}

.wp-aside-widgets ul {
  flex-direction: column;
  display: flex;
}

.wp-aside-widgets .widget {
  flex-direction: column;
  gap: 1.5rem;
  display: flex;
}

@media (width <= 61.999em) {
  .wp-aside-widgets .widget {
    --content-display: none;
    --svg-rotate: 0deg;
  }

  .wp-aside-widgets .widget ul, .wp-aside-widgets .widget form {
    display: var(--content-display);
  }

  .wp-aside-widgets .widget.--open {
    --content-display: flex;
    --svg-rotate: 180deg;
  }
}

.wp-aside-widgets .widgettitle {
  --size: 1.5rem;
  font-size: var(--size);
  font-weight: 700;
  display: block;
  position: relative;
}

@media (width <= 47.999em) {
  .wp-aside-widgets .widgettitle {
    --size: 1.25rem;
  }
}

.wp-aside-widgets .wp-caption {
  flex-direction: column;
  gap: .5rem;
  max-width: 100%;
  margin: 0;
  display: flex;
}

.wp-aside-widgets li {
  --color: #081828;
  display: block;
}

.wp-aside-widgets li > a {
  color: var(--color);
  padding-block: .5rem;
  min-height: 2.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  text-decoration-color: #0000;
  transition: text-decoration-color .3s;
  display: block;
}

.wp-aside-widgets li > a:hover {
  -webkit-text-decoration-color: var(--color);
  text-decoration-color: var(--color);
}

.widget__dropdown-btn {
  --size: 1.5rem;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.widget__dropdown-btn svg {
  rotate: var(--svg-rotate);
  transition: rotate .3s;
}

@media (width >= 62em) {
  .widget__dropdown-btn {
    display: none;
  }
}

.wp-aside-widgets .sub-menu__button {
  --size: 2.5rem;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.wp-aside-widgets .sub-menu__button svg {
  rotate: var(--svg-rotate);
  transition: rotate .3s;
}

.wp-breadcrumbs {
  --gap: 1.5rem;
}

.wp-breadcrumbs__group > span {
  gap: var(--gap);
  display: flex;
}

@media (width <= 47.999em) {
  .wp-breadcrumbs__group {
    display: none;
  }
}

.wp-breadcrumbs__group > span span {
  --height: 3.5rem;
  min-height: var(--height);
  align-items: center;
  display: flex;
}

.wp-breadcrumbs__group > span span a {
  display: block;
}

.wp-breadcrumbs__group > span span:first-child a {
  color: #0000;
  height: var(--height);
  -webkit-user-select: none;
  user-select: none;
  background-image: url("home.17d2dc7d.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1rem;
  width: 1.5rem;
  display: inline-block;
  position: relative;
}

.wp-breadcrumbs__group > span span.breadcrumb_last {
  font-weight: 600;
}

.wp-breadcrumbs__group > span span:not(:first-child) {
  position: relative;
}

.wp-breadcrumbs__group > span span:not(:first-child):before {
  content: "";
  left: calc(var(--gap) / -2);
  background-color: #2f4c4f;
  width: .25rem;
  height: .25rem;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.entry-content > * + * {
  margin-block-start: 1rem;
}

.entry-content strong:empty, .entry-content b:empty, .entry-content p:empty {
  display: none;
}

.entry-content hr {
  background: #ebebeb;
  border: none;
  height: .0625rem;
  margin-block: 3rem;
}

.entry-content .wp-caption {
  text-align: center;
  border: 0;
  max-width: 100%;
  padding: 0;
}

.entry-content .wp-caption-text {
  text-align: center;
  margin-bottom: 0;
  margin-left: 0;
  padding: .25rem .625rem;
  font-size: 1rem;
  transform: none;
}

.entry-content blockquote {
  background-color: #f2f7f5;
  flex-direction: column;
  gap: .5rem;
  margin-block: 2rem;
  padding: 1rem;
  font-style: italic;
  font-weight: 400;
  display: flex;
  position: relative;
}

.entry-content blockquote p {
  margin-bottom: 0;
  font-style: italic;
}

.entry-content .column {
  width: 100%;
  min-height: .0625rem;
  margin-bottom: 1.5rem;
  padding-left: .9375rem;
  padding-right: .9375rem;
  position: relative;
}

.entry-content .column img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
  float: none !important;
}

@media (width <= 61.999em) {
  .entry-content .column h2:first-of-type, .entry-content .column h3:first-of-type, .entry-content .column h4:first-of-type, .entry-content .column h5:first-of-type, .entry-content .column h6:first-of-type {
    margin-top: 0;
  }
}

.entry-content .columns-1, .entry-content .columns-1 .column {
  display: block;
}

.entry-content .columns-2 {
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  display: grid;
}

@media (width <= 61.999em) {
  .entry-content .columns-2 {
    flex-direction: column;
    display: flex;
  }
}

.entry-content .columns-3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  display: grid;
}

@media (width <= 61.999em) {
  .entry-content .columns-3 {
    flex-direction: column;
    display: flex;
  }
}

.entry-content .columns-4 {
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  display: grid;
}

@media (width <= 74.999em) {
  .entry-content .columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 61.999em) {
  .entry-content .columns-4 {
    flex-direction: column;
    display: flex;
  }
}

.entry-content h1, .entry-content h2, .entry-content h3, .entry-content h4, .entry-content h5, .entry-content h6 {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.entry-content h1 {
  margin-top: 0;
  position: relative;
}

.entry-content h2 {
  position: relative;
}

.entry-content > h2:first-child, .entry-content > h3:first-child {
  margin-top: 0;
}

.entry-content > a {
  display: block;
}

.entry-content img {
  max-width: 100%;
  margin-block: 1rem;
}

.entry-content ol > li {
  counter-increment: item;
  padding-left: 1.5rem;
  position: relative;
}

.entry-content ol > li:before {
  content: counter(item) ".";
  color: #2f4c4f;
  width: 1.75rem;
  height: 1.75rem;
  margin-left: -2.1875rem;
  margin-right: .5rem;
  padding: .0625rem .25rem .0625rem .375rem;
  font-size: 1.125rem;
  font-weight: 700;
  display: inline-flex;
  top: .0625rem;
}

.entry-content ol > li ol > li {
  counter-increment: sub-item;
}

.entry-content ol > li ol > li:before {
  content: counter(sub-item) ".";
}

.entry-content ol > li ol > li ol > li {
  counter-increment: sub-sub-item;
}

.entry-content ol > li ol > li ol > li:before {
  content: counter(sub-sub-item) ".";
}

.entry-content ol[style*="list-style-type: lower-alpha"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-alpha"] li:before {
  content: counter(item, lower-alpha) ")";
}

.entry-content ol[style*="list-style-type: upper-alpha"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-alpha"] li:before {
  content: counter(item, upper-alpha) ")";
}

.entry-content ol[style*="list-style-type: lower-latin"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-latin"] li:before {
  content: counter(item, lower-latin) ")";
}

.entry-content ol[style*="list-style-type: upper-latin"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-latin"] li:before {
  content: counter(item, upper-latin) ")";
}

.entry-content ol[style*="list-style-type: lower-greek"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-greek"] li:before {
  content: counter(item, lower-greek) ")";
}

.entry-content ol[style*="list-style-type: upper-greek"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-greek"] li:before {
  content: counter(item, upper-greek) ")";
}

.entry-content ol[style*="list-style-type: lower-roman"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-roman"] li:before {
  content: counter(item, lower-roman) ")";
}

.entry-content ol[style*="list-style-type: upper-roman"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-roman"] li:before {
  content: counter(item, upper-roman) ")";
}

.entry-content ul {
  list-style-type: none;
}

.entry-content ul > li {
  padding-left: 1.5rem;
  position: relative;
}

.entry-content ul > li:before {
  content: "";
  background-color: #2f4c4f;
  width: .5rem;
  height: .5rem;
  display: block;
  position: absolute;
  top: .5rem;
  left: 0;
}

.entry-content ul, .entry-content ol {
  margin: 1.5rem 0 0;
  padding: 0;
  list-style: none;
}

.entry-content ul ul, .entry-content ul ol, .entry-content ol ul, .entry-content ol ol {
  margin-top: .5rem;
  margin-bottom: 0;
}

.entry-content ul li, .entry-content ol li {
  margin-bottom: .5rem;
}

p {
  margin-bottom: 0;
}

p a {
  display: inline;
}

.entry-content .table-responsive {
  border: 1rem solid #fff;
  margin: 1rem 0;
  overflow: auto;
  box-shadow: 0 0 0 .0625rem #ebebeb;
}

.entry-content th, .entry-content td, .entry-content tr {
  border: none !important;
}

.entry-content td, .entry-content th {
  text-align: center;
  padding: 1rem;
  position: relative;
}

.entry-content thead {
  color: #fff;
  background-color: #2f4c4f;
  font-size: 1rem;
  font-weight: bold;
}

.entry-content tbody {
  color: #1d1d1b;
  background-color: #fff;
  font-size: .875rem;
}

.entry-content tbody tr:nth-child(2n) {
  background-color: #ebebeb63;
}

.entry-content tbody td, .entry-content tbody th {
  padding: .5rem;
}

.entry-content tbody td:before, .entry-content tbody th:before {
  content: "";
  background: #ebebeb;
  width: .0625rem;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.entry-content tbody td:last-child:before, .entry-content tbody th:last-child:before {
  display: none;
}

.entry-content table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0 !important;
}

.entry-content table p {
  font-size: 1rem;
  transform: none;
  margin-bottom: 0 !important;
}

.g-search-results {
  flex-direction: column;
  gap: 2rem;
  padding-top: 5rem;
  display: flex;
}

.i-search-result {
  max-width: 80ch;
}

.i-search-result__heading-link {
  color: #89001c;
  text-decoration-color: #0000;
}

.i-search-result__heading-link:hover {
  color: #89001c;
  text-decoration-color: #89001c;
}

.i-search-result__link {
  text-decoration-color: #0000;
  transition-property: color, text-decoration-color;
}

.i-search-result__link:hover {
  color: #2f4c4f;
  text-decoration-color: #2f4c4f;
}

.entry-content .gallery-item {
  margin: 0;
  padding: 0;
  position: relative;
}

.entry-content .gallery-item a {
  transition: opacity .3s;
  display: block;
}

.entry-content .gallery-item a:hover {
  opacity: .7;
}

.entry-content .gallery-item img {
  object-fit: contain;
  height: 100%;
  margin: 0 auto;
}

.search-form__wrap {
  align-items: center;
  gap: 1rem 2rem;
  max-width: 80ch;
  display: flex;
  position: relative;
}

@media (width <= 61.999em) {
  .search-form__wrap {
    flex-wrap: wrap;
  }
}

.search-form__heading {
  flex-shrink: 0;
}

.search-form {
  background-color: #fff;
  flex-grow: 1;
  display: flex;
  position: relative;
  box-shadow: inset 0 0 0 .0625rem #e8eaef;
}

.search-form__input {
  color: #302748;
  background: none;
  border: 0;
  outline: 0;
  flex-grow: 1;
  padding: 0 1rem;
  font-weight: 500;
}

@media (width <= 35.999em) {
  .search-form__input {
    min-width: 0;
  }
}

.search-form__submit {
  cursor: pointer;
  background-color: #0000;
  border: none;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  transition: background-color .2s;
  display: flex;
}

.search-form__submit svg {
  fill: #2f4c4f;
  width: 1.125rem;
  height: 1.125rem;
  transition: fill .2s;
}

.search-form__submit:hover {
  background-color: #f4f5f7;
}

.search-form__submit:hover svg {
  fill: #fff;
}

.search-form__submit:active, .search-form__submit:focus {
  outline: 0;
}

.entry-content .gallery {
  align-items: center;
  gap: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: grid;
}

.entry-content .gallery > br {
  display: none;
}

.entry-content .gallery-columns-9 {
  grid-template-columns: repeat(8, 1fr);
}

@media (width <= 61.999em) {
  .entry-content .gallery-columns-9 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-8 {
  grid-template-columns: repeat(8, 1fr);
}

@media (width <= 61.999em) {
  .entry-content .gallery-columns-8 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-7 {
  grid-template-columns: repeat(7, 1fr);
}

@media (width <= 61.999em) {
  .entry-content .gallery-columns-7 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-6 {
  grid-template-columns: repeat(6, 1fr);
}

@media (width <= 61.999em) {
  .entry-content .gallery-columns-6 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-5 {
  grid-template-columns: repeat(5, 1fr);
}

@media (width <= 47.999em) {
  .entry-content .gallery-columns-5 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-4 {
  grid-template-columns: repeat(4, 1fr);
}

@media (width <= 47.999em) {
  .entry-content .gallery-columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-3 {
  grid-template-columns: repeat(3, 1fr);
}

.entry-content .gallery-columns-2 {
  grid-template-columns: repeat(2, 1fr);
}

.entry-content .wp-gallery-no-gutter .gallery {
  gap: 0;
}
/*# sourceMappingURL=index.css.map */
