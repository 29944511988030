.i-service {
  --size: 64px;

  padding-block: 10px;
  padding-inline: 8px;
  gap: 4px;
  width: fit-content;
  min-height: var(--size);
  min-width: var(--size);
}
