.g-partner {
  &.--hidden {
    display: none;
  }

  @include media-breakpoint-down(xl) {
    max-width: 720px;
    width: 100%;
    margin-inline: auto;
  }

  @include media-breakpoint-down(lg) {
    max-width: 360px;
  }
}
