@use "settings/s-colors.scss" as colors;

.s-price-list {
  margin-bottom: 64px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 32px;
  }
}

.s-price-list__table {
  border: 1px solid colors.$tertiary-400;
}

.s-price-list__header {
  grid-template-columns: 1fr 1fr 152px 1fr;
  grid-template-areas: "wattage price grant code";
  column-gap: 4px;

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr auto;
    grid-template-areas:
      "wattage grant"
      "price grant"
      "code grant";
  }
}

.s-price-list__wattage {
  grid-area: wattage;
  max-width: 180px;
  width: 100%;

  @include media-breakpoint-only(xl) {
    max-width: 125px;
  }

  @include media-breakpoint-only(md) {
    max-width: 115px;
  }

  @include media-breakpoint-down(md) {
    max-width: 100%;
    width: auto;
  }
}

.s-price-list__price {
  grid-area: price;
}

.s-price-list__code {
  grid-area: code;
  max-width: 178px;
  width: 100%;
  padding-left: 40px;

  @include media-breakpoint-only(xl) {
    max-width: 143px;
  }

  @include media-breakpoint-only(lg) {
    max-width: 170px;
  }

  @include media-breakpoint-only(md) {
    max-width: 115px;
  }

  @include media-breakpoint-down(md) {
    max-width: 100%;
    width: auto;
    padding-left: 0;
  }
}

.s-price-list__grant {
  grid-area: grant;
  max-width: 152px;
  width: 100%;

  @include media-breakpoint-only(xl) {
    max-width: 135px;
  }

  @include media-breakpoint-down(md) {
    max-width: 100%;
    width: auto;
    margin-left: auto;
  }
}
