@use "settings/s-colors" as colors;

.i-hero__body {
  --max-width: 720px;

  max-width: var(--max-width);
  order: 1;

  @include media-breakpoint-down(xxl) {
    --max-width: 544px;
  }

  @include media-breakpoint-down(xl) {
    --max-width: 488px;
  }

  @include media-breakpoint-down(lg) {
    --max-width: 100%;
  }
}

.i-hero {
  @include media-breakpoint-up(xl) {
    margin-bottom: 52px;
  }

  @include media-breakpoint-between(sm, xl) {
    .g-buttons {
      --spacing: 48px;
    }
  }
}

.i-hero__image {
  --width: 624px;
  --y-translate: -48px;
  --x-translate: 0;

  width: var(--width);
  transform: translate(var(--x-translate), var(--y-translate));

  > .img img {
    aspect-ratio: 1 / 0.794;
  }

  @include media-breakpoint-down(xxl) {
    --width: 544px;
  }

  @include media-breakpoint-down(xl) {
    --width: 408px;
    --y-translate: -32px;
    --x-translate: 0px;
  }

  @include media-breakpoint-down(lg) {
    position: static;
    top: auto;
    right: auto;
    transform: translate3d(0, 0, 0);
    margin-inline: auto;
  }

  @include media-breakpoint-down(sm) {
    --width: 100%;
  }
}

.i-hero__play {
  --play-width: 128px;
  --bottom: 124px;

  width: var(--play-width);
  left: 16px;
  bottom: var(--bottom);
  transform: translateY(50%);

  @include media-breakpoint-down(xxl) {
    --play-width: 96px;
    --bottom: 80px;
  }

  @include media-breakpoint-down(xl) {
    --bottom: 48px;
  }
}

.i-hero__img-decoration {
  --decoration-width: 176px;

  width: var(--decoration-width);

  @include media-breakpoint-down(xxl) {
    --decoration-width: 128px;
  }

  @include media-breakpoint-down(lg) {
    --decoration-width: 96px;
  }
}

.i-hero__header.base-header {
  max-width: 100%;

  .base-heading.u-h1 {
    @include media-breakpoint-down(xxl) {
      --size: 40px;
    }

    @include media-breakpoint-down(xl) {
      --size: 32px;
      --line-height: var(--size);
    }
  }
}

.i-hero__link {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}

.i-hero__links {
  --spacing: 64px;

  margin-top: var(--spacing);

  @include media-breakpoint-down(sm) {
    --spacing: 24px;

    flex-direction: column;
    align-items: center;
  }
}
