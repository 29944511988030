$gaps: (
  "xs": 4px,
  "sm": 8px,
  "md": 16px,
  "lg": 24px,
  "xl": 32px,
  "xxl": 40px,
  "none": 0,
);

$aligns: (
  "left": "flex-start",
  "right": "flex-end",
  "center": "center",
  "between": "space-between",
);

.g-base {
  display: flex;
  flex-wrap: wrap;

  @each $key, $size in $gaps {
    &.--gap-#{$key} {
      gap: $size;
    }
  }

  @each $key, $align in $aligns {
    &.--align-#{$key} {
      justify-content: #{$align};
    }
  }
}
