@use "settings/s-colors" as colors;

.f-checkbox__visual {
  width: var(--size);
  height: var(--size);
  border: 1px solid var(--color);
  background-color: colors.$white;
  transition: background-color 0.3s ease;
  border-radius: var(--radius);

  svg {
    opacity: 0;
    transform: scale(0.2);
    transition: opacity 0.3s ease;
    transition-property: opacity, transform;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
    width: calc(var(--size) / 2);
    height: calc(var(--size) / 2);
    background-color: colors.$white;
    border-radius: calc(var(--size) / 2);
    opacity: 0;
    transform: scale(0.2);
    transition: opacity 0.3s ease;
    transition-property: opacity, transform;
  }
}

.f-checkbox__input {
  left: calc(var(--size) / 2);
  top: calc(var(--size) / 2);
  transform: translateX(-50%);
  pointer-events: none;
  opacity: 0;

  &:checked ~ .f-checkbox__visual {
    background-color: var(--color);

    &::after,
    svg {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.f-checkbox__title {
  a {
    color: var(--color);
    font-weight: 700;
  }
}

.f-checkbox {
  --color: #{colors.$primary};
  --radius: 4px;
  --size: 24px;

  cursor: pointer;

  &.--disabled {
    --color: #{colors.$gray};

    .f-checkbox__title {
      color: var(--color);
    }
  }

  &.--radio {
    --radius: calc(var(--size) / 2);

    .f-checkbox__visual {
      &::after {
        display: block;
      }
    }
  }
}
