@use "settings/s-colors.scss" as colors;

.main-footer-column__link {
  text-decoration-color: transparent;

  &:hover,
  &:focus {
    text-decoration-color: colors.$text-500;
  }
}
