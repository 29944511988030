@use "settings/s-colors.scss" as colors;

$placeholderColor: colors.$gray-200;

.img {
  overflow: hidden;

  &.--ratio-4-3 {
    aspect-ratio: 4/3;
  }

  &.--ratio-16-9 {
    aspect-ratio: 16/9;
  }

  &.--ratio-3-2 {
    aspect-ratio: 3/2;
  }

  &.--ratio-1-1 {
    aspect-ratio: 1/1;
  }

  &.--ratio-3-4 {
    aspect-ratio: 3/4;
  }

  &.--contain {
    width: 100%;
    height: 100%;

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }

    picture {
      height: 100%;
      width: 100%;
    }
  }

  &.--cover {
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    picture {
      height: 100%;
      width: 100%;
    }
  }

  &.--placeholder {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background-color: $placeholderColor;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: -1;
    }
  }
}
