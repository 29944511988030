@use "settings/s-colors" as colors;

.m-header {
  --min-height: 104px;
  --padding-y: 16px;

  top: 0;
  left: 0;
  position: sticky;
  z-index: 10;
  background-color: colors.$white;
  transition: transform 0.3s ease;
}

.m-header__body {
  min-height: var(--min-height);
  padding-block: var(--padding-y);

  @include media-breakpoint-down(xl) {
    --min-height: 80px;
    --padding-y: 8px;
  }
}

.m-header__side-menu {
  @include media-breakpoint-between(lg, xxl) {
    gap: 8px;
  }
}

.m-header__strip {
  background: colors.$primary-500;
  height: 8px;
  width: 100%;
  max-width: 100%;
}

.m-header__dealer-btn.btn {
  min-width: auto;
}
