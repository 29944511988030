.s-partner-list__empty {
  &.--hidden {
    display: none;
  }
}

.s-partner-list__load-more-wrap {
  &.--hidden {
    display: none;
  }
}

.s-partner-list__form {
  max-width: 609px;
  width: 100%;
}
