@use "settings/s-general" as general;
@use "settings/s-colors" as colors;

$padding-x: 24px;
$padding-y: 10px;

$background: colors.$white;
$color-placeholder: colors.$text-500;

$text-area-min-height: 168px;

.f-base {
  display: block;
  width: 100%;
  background-color: $background;
  color: colors.$text-500;
  background-clip: padding-box;
  padding: $padding-y $padding-x;
  padding-right: 24px;
  appearance: none;
  border: none;
  min-height: 56px;
  font-weight: 500;

  &:focus {
    border-color: none;
    outline: none;
  }

  &::placeholder {
    opacity: 1;
    color: $color-placeholder;
  }
}

textarea.f-base {
  min-height: $text-area-min-height;
  padding: 20px 16px 16px 24px;
}
